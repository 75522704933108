.dbt_links {
  color: rgb(12, 0, 195);
}
.panel {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-primary {
  border-color: #1d3b6d;
}
.score-heading {
  background-color: #f5f7f8;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 4px;
  display: flex;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  align-items: center;
  border: 0.7px solid #bbb;
}
.score-title {
  margin: 0 auto;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}
.grade-table-header {
  font-weight: bold!important; 
  text-transform: uppercase;
  border: 1px solid rgba(224, 224, 224, 1);
}
.grade-table-body tr td {
  border: 1px solid rgba(224, 224, 224, 1);
}
.grade-table-body tr:nth-of-type(odd){
  background-color: rgba(0,0,0,.05);
}
.score-button {
  display: flex;
  width: fit-content;
}
.glossary-pagination-container {
  padding: 4px;
  border: 0.7px solid #bbb;
}
.glossary-card-list {
  margin-top: 20px;
}
