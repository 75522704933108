.kpi-container {
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.kpi-container::-webkit-scrollbar {
  display: none;
}

.kpi-grid-container {
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
}

.kpi-grid-item {
  margin: 0;
  display: flex;
  padding: 16px !important;
  min-height: 400px;
  height:auto !important;
  width: auto !important;
  position: relative;
  box-sizing: border-box;
}

.kpi-chart-paper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  border-radius: 8px !important;
  border: 1px solid #ccc;
  padding: 10px;
  height: auto;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

}

.kpi-chart-title {
  font-family: "Helvetica Neue", Arial, sans-serif !important;
  margin-bottom: 16px !important;
  font-style: italic !important;
  line-height: 1.2 !important;
  padding: 0 !important;
  height: auto;
}

.kpi-chart-box {
  flex: 1;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: auto;
}

.kpi-tooltip-container {
    background-color: '#fff';
    border: '1px solid #ccc';
    padding: '10px';
    font-family: "'Helvetica Neue', Arial, sans-serif";
    font-size: '14px';
    font-weight: '400';
    line-height:  '1.5';
    text-align: 'left';
}

.kpi-legend-container {
  display: flex;
  justify-content: center;
  height: auto;
  padding: 2px 0;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.kpi-divider {
  margin: 10px 0 !important;
}

.kpi-tooltip-text {
  margin: 0;
  padding: 2px 0;
}

.kpi-tooltip-text-bold {
  margin: 0;
  font-weight: bold;
  padding: 2px 0;
}

.kpi-tooltip-text-nps {
  color: blue;
  margin: 0;
  padding: 2px 0;
}

.kpi-tooltip-text-promoters {
  color: #70B77E;
  margin: 0;
  padding: 2px 0;
}

.kpi-tooltip-text-passives {
  color: #FFDD57;
  margin: 0;
  padding: 2px 0;
}

.kpi-tooltip-text-detractors {
  color: #E57373;
  margin: 0;
  padding: 2px 0;
}

.kpi-empty-state {
  text-align: center;
  padding: 24px;
}

/* Legend box styles */
.kpi-boxContainer {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

/* Color boxes for legend */
.kpi-nps {
  width: 16px;
  height: 16px;
  background-color: blue;
  margin-right: 8px;
}

.kpi-promoters {
  width: 16px;
  height: 16px;
  background-color: #70B77E;
  margin-right: 8px;
}

.kpi-passives {
  width: 16px;
  height: 16px;
  background-color: #FFDD57;
  margin-right: 8px;
}

.kpi-detractors {
  width: 16px;
  height: 16px;
  background-color: #E57373;
  margin-right: 8px;
}

.kpi-annotation {
  font-size: 12px !important;
  color: #666;
}

/* Media query for responsive design */
@media screen and (max-width: 768px) {
  .grid-item {
    width: 100% !important;
  }
}
