button#dropdown-basic-button {
  width: 100%;
  background: #e12726;
  border: #e12726;
}
.modalBody {
  overflow-y: visible;
  height: auto !important;
  max-height: none !important;
}

.rowFlex {
  display: flex;
  margin: 10px, 10px;
}

.flexItem {
  flex: 1;
}

.jira-tracker-header {
  font-size: 1.1em;
  border-left: 1px solid black;
  border-right: 1px solid black;
  /* font-weight: 600 !important; */
  /* background-color: #dde6ed; */
  opacity: 0.8;
}

.jira-tracker-cell {
  border: 1px solid black;
}

.btn-sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.addModalBtn {
  display: flex;
  justify-content: center;
  width: 100px;
}
.tbody {
  border-top: none !important;
}

#alert-dialog-slide-description {
  font-size: 17px;
  color: #262626;
}

.form-floating > label {
  margin-left: 9px;
}
.email-link {
  color: blue !important;
}
