.npsAdminBody > tr > td:nth-child(1) {
  width: 2%;
}
.group-nps-modal > div {
  width: 95% !important;
  max-width: 100% !important;
}

.npsgroupmembers > tr > td:nth-child(1) {
  width: 2%;
}

.ql-mention-list-container {
  top: 50px !important;
}
