  .word-cloud-container {
    position: relative;
    width: 600px;
    min-height: 200px;
  }

  .word-hover-info {
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: center;
    background-color: rgba(240, 240, 240, 0.9);
    color: #333;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(169, 169, 169, 0.8);
    font-size: 14px;
    z-index: 10;
  }
