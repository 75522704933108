.card-section-nps {
  display: flex;
  margin: 10px;
  flex-wrap: wrap;
  gap:5px;
}
.user-form-heading {
  display: flex;
  width: 100%;
  color: #000;
  border: 1px solid #9ac2f180;
  padding: 15px;
  background: #b5d7ff82;
}
.logo-nps {
  margin-left: auto;
  width: 20%;
  height: 20%;
}
.date-label {
  font-weight: 500;
  font-size: 11px;
  line-height: 1rem;
  display: flex;
  align-items: center;
}
.date-label-count {
  line-height: 1;
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
  padding: .5rem;
  display: inline-flex;
  align-items: center;
}
.card_survey_title {
  display: block;
  font-weight: 600;
  color: #071437;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.survey_status {
  color: #4b5675;
  background-color: #f9f9f9;
  justify-content: center;
  line-height: 1;
  border-radius: .25rem;
  padding: .5rem;
  border: 1px solid #dbdfe9;
  font-weight: 600;
  font-size: .6875rem;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.survey_title {
  font-weight: 500;
  color: #071437;
  font-size: 1.3rem;
  line-height: 2.5rem;
  margin-bottom: 8px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.survey_description{
  font-size: 0.8em;
  line-height: 1.2rem;
  color: #4b5675;
  margin-bottom: 10px;
  max-width: 278px;


}

.nps_survey_title {
  display: block;
  font-weight: 600;
  color: #071437;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nps_survey_desc {
  display: block;
  font-weight: 600;
  color: #686868;
  font-size: 1rem;
  line-height: 2.5rem;
  margin-bottom: 1px;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no_surveys_message{
  font-family: Arial, sans-serif;
  margin-top: 20px;
  margin-left: 10px;
  color: #777;
}
