.all-questions {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  padding: 9px;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.all-questions-container {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}

.all-questions-left {
  display: flex;
  margin-right: 30px;
}

.all-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: small;
}

.all-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.all-option > p {
  font-size: large;
}

.question-answer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.question-answer > a {
  color: #0151f0d8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.question-answer > p > a {
  color: #0151f0d8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.question-answer > p > a:hover {
  color: #1653ccd8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.question-answer > a:hover {
  color: #1649b1d8;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.question-answer > p {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.7);
  /* margin-bottom: 10px; */
}

.author {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: flex-end;
}

.author > small {
  color: rgba(0, 0, 0, 0.5);
  /* margin-bottom: 5px; */
}

.auth-details {
  display: flex;
  align-items: center;
}

.auth-details > p {
  margin-left: 5px;
  font-size: small;
  color: #0151f0d8;
}

.comments {
  margin: 10px 0;
  width: 90%;
  margin-left: auto;
}

.comment {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}

.comment > p {
  margin: 10px 0;
}

.comment > p > span {
  padding: 3px;
  background-color: #0151f028;
  color: #0151f0d8;
  border-radius: 2px;
}

.comments > p {
  margin-left: -30px;
  margin-top: 20px;
  font-size: small;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}

.comments > p:hover {
  color: #0151f0a2;
}
.tagBtn {
  font-size: 14px;
  margin: 11px 4px;
  padding: 4px 8px;
  background-color: rgb(225 236 244);
  border-radius: 5px;
  cursor: pointer;
  border: none;
}
.edit {
  display: flex;
  margin-left: auto;
}
.main-page-tags {
  flex-direction: row;
  display: flex;
  width: 100%;
  /* height: 60px; */
  /* display: flex; */
  position: relative;
  margin-top: 10px;
}
