.manage-button {
    background-color:#fff!important;
    color:#000!important;
}
.close-idea-button {
    color: #dc3545!important; 
    border: 1px solid #dc3545!important;
}
.ideas_toolbar {
    min-height: 74px !important;
    margin-top: -10px;
    padding-top: 10px;
}
.idea-summary-label {
    color: #78829d;
    font-size: .875rem;
    line-height: 1.25rem;
}
.outlined-success {
    color: #198754 !important;
    border-color: #198754 !important;
}
.outlined-warning {
    color: #f15700 !important;
    border-color: #f15700 !important;
}

.outlined-danger {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
}
.area_dropdown {
    margin-left: 5px;
    border-radius: .25rem;
    line-height: 1.5;
    padding: .375rem 2.25rem .375rem .75rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
}
.idea-sort-buttons .btn, .search-button {
    border-color: #adb5bd;
    background-color: #fff;
    color: #000;
}
.idea-sort-buttons .btn:active {
    background-color: #fff;
    color: #000;
    border-color: #343a40;
}

.idea-sort-buttons .btn {
    margin-top: 14px;
    padding: 7px;
}
.idea-sort-pagination-buttons .btn {
    margin: 0;
}
.idea_title {
    display: block;
    font-weight: 600;
    color: #071437;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1px;
    max-width: 278px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.idea_text_capitalize {
    text-transform: capitalize;
}
.idea_user {
    text-transform: capitalize;
    max-width: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.idea_category_badge,
.idea_complexity_badge{
    color: #4b5675;
    background-color: #f9f9f9;
    justify-content: center;
    line-height: 1;
    border-radius: .25rem;
    padding: .5rem;
    border: 1px solid #dbdfe9;
    font-weight: 600;
    font-size: .6875rem;
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}
.assigned-to {
    margin-left: 10px;
    font-size: 1rem;
    text-transform: capitalize;
    border-style: dashed;
    border-radius: .375rem;
    color: #071437;
}
.idea-table-body tr td {
    padding: 4px;
}
.idea_label {
    color: #78829d;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .6875rem;
    line-height: .75rem;
}
.idea_desc {
    color: #4b5675;
    font-size: .9375rem;
    line-height: 1.375rem;
    padding-bottom: 1rem;
}
.MuiAvatar-circular {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.idea-a-tag {
    color: rgb(12, 0, 195);
}
.idea-pagination {
    margin-bottom: 0;
}
.badge-deprecate {
    color: #fff;
    background-color: #808290;
    border-color: #808290;
}
.badge-danger {
    color: #f8285a;
    background-color: #ffeef3;
    border-color: #f8285a33;
}
.badge-info {
    color: #7239ea;
    background-color: #f8f5ff;
    border-color: #7239ea33;
}
.badge-warning {
    color: #f6b100;
    background-color: #fff8dd;;
    border-color: #f6b10033;
}
.badge-primary {
    color: #1b84ff;
    background-color: #eff6ff;
    border-color: #1b84ff33;
}
.badge-success {
    color: #17c653;
    background-color: #eafff1;
    border-color: #17c65333;
}
.cards {
    display: flex;
    gap: 5px;
    align-content: center;
    flex-wrap:wrap;
}

.vote-label {
    color: #78829d;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
}
.vote-label-count {
    color: #071437;
    line-height: 1;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1;
    padding: .5rem;
    display: inline-flex;
    align-items: center;
}
.side-box {
    border: 0.5px dashed #c4cada;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .425rem;
    padding-right: .425rem;
    display: grid;
    border-radius: .375rem;
    gap: 0.275rem;
}
.idea-button {
    border: 1px dashed #1b84ff!important;
    color: #1b84ff!important;
    line-height: 1!important;
    font-weight: 600!important;
    min-width: initial!important;
    font-size: .8125rem!important;
    padding-bottom: .25em!important;
}
.idea-button-liked {
    color: #fff!important;
    background-color: #1b84ff!important;
}
.idea-comment-section-heading {
    color: #071437;
    font-size: 1.5rem;
    margin: 0.75em;
}
.idea-comments-cont {
    background-color: #00000008;
}
.idea-comment {
    border: 1px solid #F1F1F4;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border-radius: 0.625rem;
    margin: 1em;
    padding: 1em;
    background-color: #fff
}
.idea-description-wrap {
    width: 68em;
    word-wrap: break-word;
}
.idea-comment-heading {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    border-bottom: 1px solid #e9ecef;
}
.idea-comment-heading-user {
    color: #071437;
    font-weight: 600;
    text-transform: capitalize;
}
.idea-comment-heading-date {
    color: #99A1B7;
    font-weight: 600;
}
.post-comments-form {
    display: flex;
    flex-direction: row;
}
.idea-post-comment-btn-cont {
    display: flex;
    flex-basis: 25%;
    align-items: center;
}
.idea-post-comment-btn {
    color: #1976d2!important;
    border: 1px solid #0d6efd!important;
    max-width: fit-content;
    width: 100%;
}
.idea-post-comment-btn-icon {
    height: 0.6em!important;
    width: 0.6em!important;
    margin-left: 0.25em;
}
.post-comments-form .react-quill {
    background-color: #fff;
    border: 1px solid #F1F1F4;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px 0px;
    border-radius: 0.625rem;
    margin: 1em;
    flex-basis: 75%;
}
.post-comments-form  .ql-toolbar {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}
.post-comments-form .ql-container {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}
.ideas_switch > .MuiSwitch-track {
    background-color: #eafff1;
}
.ideas_switch > .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #65C466;
}

.idea-footer {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
    border-bottom: 1px solid #0000002d;
}
.idea-footer-vote {
    display: flex;
    gap: 1em;
}
.idea-footer-action {
    display: flex;
    gap: 1em;
}
