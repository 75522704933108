.main-page {
  display: flex;
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-top {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.main-top > h2 {
  font-weight: 400;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.8);
}

.Askbutton {
  padding: 10px;
  background-color: #0095ff;
  color: #fff;
  border: 2px solid #007cd446;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}

.main-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
}

.main-filter {
  display: flex;
  align-items: center;
}

.main-tabs {
  display: flex;
  border: 1px solid rgb(179 179 179);
  border-radius: 4px;
}

.main-tab {
  border-right: 1px solid #ddd;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  border-radius: 0;
  padding: 3px;
}
.main-tab:hover {
  background: #e1ecf4;
}
.main-tab-active {
  background: #e1ecf4;
  border-right: 1px solid #ddd;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  border-radius: 0;
  padding: 3px;
}

.filterbtn {
  background: none;
  color: #000;
  border: none;
  padding: 4px;
}
.filterbtn:hover {
  background: none;
}
.main-tab > a {
  font-size: small;
}

.main-filter-item {
  display: flex;
  padding: 5px;
  border: 1px solid #0095ff;
  border-radius: 3px;
  background-color: #00ccff17;
  font-size: small;
  align-items: center;
  color: #007cd4;
  cursor: pointer;
}

.questions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  /* border-bottom: 1px solid #eee; */
  width: 100%;
}

pre {
  display: flex;
  width: 90px;
  flex-wrap: wrap;
}
.pagination {
  display: flex;
  letter-spacing: 1px;
  font-family: monospace;
}
.page-item {
  margin: 5px;
}
ul.pagination li.active {
  background-color: #1d3b6d;
}

.search-button {
  background: green;
  padding: 7px;
  font-size: 15px;
  border: 1px solid green;
  border-radius: 2px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  color: white;
  opacity: 0.8;
  transition: 0.3s opacity;
}

.search-button:hover {
  opacity: 1;
}

.collab-search-container {
  display: flex;
  width: 90%;
  font-size: 0.9em;
  font-weight: 400;
  border: 2px solid #b6c4b6;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: 0.3s box-shadow;
}

.collab-search-container:focus-within {
  border-color: #007cd4;
  border-right: none;
  box-shadow: -8px 2px 24px -11px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -8px 2px 24px -11px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -8px 2px 24px -11px rgba(0, 0, 0, 0.39);
}

.collab-search {
  width: 100%;
  padding: 7px;
  font-size: 1.1em;
  font-weight: 400;
  outline: none;
  border: none;
}

.collab-search::placeholder {
  color: black;
  opacity: 0.4;
}

.clearbtn {
  display: inline-block;
  border: none;
  background: transparent;
  padding: 0;
}

.search-bar-collab {
  width: 57%;
  display: flex;
}

.distribution-list {
  margin-bottom: 12px;
  width: fit-content;
  background-color: #f5f7f8;
  border-radius: 4px;
  padding: 5px 10px;
}

.distribution-list h6{
  padding: 0;
  margin: 0;
}
