.add-question {
  display: flex;
  /* height: auto; */
  height: 100%;
  width: 100%;
  background-color: #fff;
  /* padding: 10px; */
  margin: 0px;
  justify-content: center;
}

.add-question-container {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 800px;
}

.head-title {
  display: flex;
  width: 100%;
}

.head-title > h1 {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 26px;
}

.question-container {
  display: flex;
  padding: 15px;
  background-color: #b6daff21;
  border-radius: 20px;
  border: 1px solid #0970d93b;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(66, 42, 42, 0.19);
}

.question-options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question-option {
  /* display: flex; */
  flex-direction: column;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  font-size: 0.9rem;
}

.title > h3 {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

.title > small {
  color: rgba(0, 0, 0, 0.8);
}

.title > input {
  margin: 5px 0px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  outline: none;
}

.title > input::placeholder {
  color: #ddd;
}
.title > input:focus {
  border: 1px solid #0054ff;
  box-shadow: 0 4px 8px 0 #0055ff23, 0 6px 20px 0 #0055ff11;
}

.quill {
  height: 100%;
}

.react-quill {
  margin: 10px 0;
  border-radius: 10px;
}

.ql-editor {
  height: 200px;
}

.Addbutton {
  padding: 6px;
  background-color: #0095ff;
  color: #fff;
  border: 2px solid #007cd446;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  margin: 18px 0px;
  max-width: fit-content;
}

.Addbutton:hover {
  background-color: #053086;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  width: 100% !important;
}

.rti--container.go2761622576 {
  background: #fff;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #e7e7e7;
  color: #1c1c1c;
  overflow: visible;
  width: 50%;
}
