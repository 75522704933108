.badge-circle {
    border-radius: 50%;
    padding: 0;
    min-width: unset;
    width: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    min-width: 1.5rem;
    line-height: 0;
    color: #ffffff;
    font-size: 0.85rem;
    font-weight: 600;
}
.badge-circle-info {
    background-color: #7239EA;
}
.badge-circle-primary {
    background-color: #1B84FF;
}
.accordion-wrapper{
    position: relative;
    overflow: hidden;
}
.accordion-summary-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Mui-expanded > .accordion-summary-content > .accordion-status-bar {
    border-bottom-right-radius: initial;
    position: absolute;
    width: 100%;
    bottom: 0;
}
