/* .css-ciy9n4-MuiPaper-root-MuiAppBar-root {
    margin-top: 61px;
    position: absolute !important;
    background-color: #1d3b6d !important;
    z-index: 0 !important;
} */
/* .css-yfo96e {
    margin-top: 42px;
    z-index: 0 !important;


} */
/* .css-t5ouwv-MuiPaper-root{
    padding: 0px !important;
}
.css-jzk4qw-MuiPaper-root-MuiAppBar-root {
    margin-top: 61px;
    position: absolute !important;
    background-color: #1d3b6d !important;
    z-index: 0 !important;

} */
/* .css-ttn1xo {
    margin-top: 42px;
    z-index: 0 !important;

} */

/* CSS */
.btn-dash {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  margin: 30px;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #8b79ee;
  color: ghostwhite;
}

.btn-dash span {
  position: relative;
  z-index: 10;
  transition: color 0.6s;
}

.btn-dash:hover span {
  color: #000;
}

.btn-dash::before,
.btn-dash::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.btn-dash::before {
  content: '';
  background: #1976d2;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.6s cubic-bezier(0.3, 1, 0.8, 1);
}

.btn-dash:hover::before {
  transform: translate3d(100%, 0, 0);
}

.dashboard {
  display: flex;
  justify-content: center;
  background: #fff;
}

.MuiDrawer-paperAnchorLeft {
  z-index: 0 !important;
  position: relative !important;
  top: -2px !important;
  float: left !important;
}
.show-more-text {
  text-decoration: underline;
  color: white;
  margin-left: 0.5em;
  cursor: pointer;
}
