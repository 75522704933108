.status-category{
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 0.65rem;
    font-weight: 600;
}
.announcement-card {
    border: 1px solid #C2CCE1;
    background-color: #fff;
    padding: 0.5rem;
}
.announcement-sender {
    text-transform: capitalize;
    font-weight: 400;
}
.announcement-sender-popup {
    display: flex;
    justify-content: flex-end;
}
.audience-announcement-popup {
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #071437;
    text-transform: capitalize;
}
.announcement-header {
    display: flex;
    gap: 0.25rem;
    align-items: center
}
.announcement-header-popup {
    display: flex;
    align-items: center;
    flex: 1;
}
.announcement-header-popup-start {
    display: flex;
    flex: 0.75 1;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
.announcement-header-popup-end {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}
.audience-announcement {
    color: #fff;
    background-color: #17a2b8;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.325rem 0.5rem;
    font-size: 0.65rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: uppercase;
}
.date-picker-control {
    width: 120px;
}
.status-category-info {
    color: #1B84FF;
}
.status-category-imp {
    color: #7239EA;
}
.status-category-critical {
    color: #17C653;
}
.status-form-group  .form-check-input[type=radio] {
    border: 1px solid rgba(0,0,0,.25);
}
.status-form-group  .form-check-input:checked[type=radio] {
    background-color: #0d6efd;
}
.active_inactive_badge {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 5em;
}
.active_badge {
    background-color: #5cb85c;
}
.inactive_badge{
    background-color: #777;
}
.announcement-desc-editor {
    height: 250px;
  max-height: 250px;
  overflow: auto;
}
