.pod-request-details {
    display: flex;
    margin-bottom: 5px;
    height: 70vh;
    overflow: hidden;
}

.pod-card {
    flex: 1;
    margin: 2px 4px;
    border: 1px solid #E2DAD6;
    border-radius: 3px;
    box-shadow: 1px 1px 8px 1px #E2DAD6;
    overflow-y: scroll;
}

.pod-card::-webkit-scrollbar {
    display: none;
}

.card-head {
    width: 100%;
    background-color: #EEEEEE;
    text-align: center;
    font-size: 1.1em;
    font-weight: 300;
    position: sticky;
    top: 0;
    padding-bottom: 5px;
}

.details-container {
    padding-left: 4px;
    padding-top: 4px;
}

.pod-text {
    border: 1px solid #E2DAD6;
    width: fit-content;
    border-radius: 3px;
    padding: 3px 8px;
    margin-top: 3px;
    margin-bottom: 2px;
    margin-left: 4px;
    background: #EEEDEB;
}

.pod-member {
    border: 1px solid #E2DAD6;
    width: fit-content;
    border-radius: 3px;
    padding: 3px 8px;
    margin: 2px;
    background: #EEEDEB;
}

.pod-member-container {
    display: flex;
    flex-wrap: wrap;
}

.members-container {
    display: flex;
    flex-direction: column;
}

.card-sub-head {
    font-size: 14px;
    margin-left: 4px;
    margin-top: 8px;
    width: fit-content;
}

.edited {
    background: rgba(235, 110, 110, 0.801);
    color: white;
}

.changed {
    background: rgba(78, 155, 62, 0.801);
    color: white;
}

.pod-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pod-top-bar {
    flex: 0.5
}

.legend-added, .legend-edited {
    margin-right: 10px;
}

.legend-edited::before {
    content: "";
    padding: 1px 9px;
    margin-right: 3px;
    border: none;
    background: rgba(235, 110, 110, 0.801);
    border-radius: 2px;
}

.legend-added::before {
    content: "";
    padding: 1px 9px;
    margin-right: 3px;
    border: none;
    background: rgba(78, 155, 62, 0.801);
    border-radius: 2px;
}

.legend-container {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cell{
    border-right: 1px solid #D8D9DA;
    padding: 8px !important;
}

.pod-badge {
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
}

.email-pod-btn {
    visibility: visible !important;
    display: inline-flex;
    margin-right: 9px;
    background-color: #1A4870;
    color: white;
    font-weight: 500;
    box-shadow: 1px 1px 2px grey;
    transition: 0.3s background-color;
}

.email-pod-btn:hover {
    color: white;
    background-color: #1F316F;
}
.pod_detail_tab {
    border-bottom: 1px solid #7E8299;
}
.pod_detail_tab .nav-link {
    color: #7E8299;
    border-bottom: 1px solid transparent;
    border-radius: 0;
}
.pod_detail_tab .nav-link.active {
    background-color: #fff;
    color: #3699FF;
    border-bottom: 1px solid #3699FF;
}
.asset_info_modal {
    padding: 0;
}
.asset_info_table {
    width: 100%;
}
.asset_info_table td{
    border: 1px solid #ddd;
    padding: 5px 5px .75rem .5rem;
    font-size: .875rem;
    line-height: 1.25rem;
}
.asset_info_table td.asset_info_label {
    width: 15em;
    color: #78829d;
    font-weight: bold;
}
.badge-success-outline {
    color: #17c653!important;
    border-color: #17c653!important;
    border-radius: 9999px!important;
    background-color: transparent!important;
}
.badge-error-outline {
    color: #f8285a!important;
    border-color: #f8285a!important;
    border-radius: 9999px!important;
    background-color: transparent!important;
}
