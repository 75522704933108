.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a:link {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* SCROLL Bar */
body::-webkit-scrollbar,
.table-container::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track,
.table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(80, 80, 80, 0.438);
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb,
.table-container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

.landing {
  justify-content: center;
  background-color: whitesmoke;
  box-shadow: 0px 1px 10px 4px #cbcbcb;
  border-radius: 9px;
  width: 40%;
  align-self: center;
  position: absolute;
  left: 30%;
  top: 30%;
  padding: 30px;
}

.homepage {
  justify-content: center;
  border: 2px solid black;
  background-color: whitesmoke;
  border-radius: 9px;
  width: 70%;
  align-self: center;
  position: absolute;
  left: 15%;
  top: 30%;
  padding: 30px;
}

.heading {
  text-align: center;
  margin: 20px 20px;
  font-size: 35px;
  font-family: sans-serif;
}

.email-btn {
  color: black;
  font-size: 1.2em;
}

.tableClone {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  justify-content: left;
  margin-left: 2rem;
}

.navbar-parent {
  /* max-height: 10vh; */
  background-color: rgb(250, 250, 250);
  box-shadow: 0px 1px 10px 4px #cbcbcb;
}

.navbar {
  font-size: 14px;
  box-shadow: 0px 2px 6px 1px #a5a5a5;
  padding: 3px;
}

.body-container {
  width: 95%;
  margin: 2px auto;
}

.dark-blue {
  background-color: #0f3460;
}

.dark-blue-text {
  color: #0f3460;
}

.padding-100 {
  padding: 130px;
}

.m_r-5 {
  margin-right: 20px;
}

.table-container {
  max-height: 74vh;
  table-layout: fixed;
  border-collapse: collapse;
  overflow-y: scroll;
  font-size: 12px;
  box-shadow: 1px 1px 9px 2px #c7c7c7ed;
  padding: 2 8px;
  background-color: white;
}

.table-container td {
  padding: 4px 5px;
  overflow: hidden;
  white-space: nowrap;
  border: 2px solid #d4d4d4ce;
  vertical-align: middle;
}

.table-container th {
  border: 2px solid #d4d4d4ce;
}

.table-container tr {
  transition: all 0.05s linear;
  max-height: 85vh;
}

.table-container tr:hover {
  background-color: #d4d4d4ce;
}

.table-container tr .form-control {
  font-size: 0.8rem;
  padding: 0.2rem 0.2rem;
}

.t-container {
  width: 95%;
  margin: 10px auto;
}

.thead {
  background-color: #1b3c6d;
  color: white;
  padding: 1rem;
  text-transform: capitalize;
  font-size: 0.8rem;
}

.disable-input {
  pointer-events: none;
  color: lightcoral;
}

.highlight {
  background-color: rgb(255, 230, 89);
}

.modal-body {
  max-height: 70vh;
  overflow-y: scroll;
}

.insert_row_model {
  width: 100%;
}

.home_grid_container {
  padding: 2rem;
}

.flex-container {
  display: flex;
}

.left-component {
  width: 40%;
  height: 88vh;
  padding-top: 20px;
  font-size: 14px;
}

.left-component .row {
  padding-right: 0;
}

.separator {
  position: relative;
  order: 2;
  width: 2%;
  height: auto;
  border-right: 1px solid rgb(206, 206, 206);
}

.right-component {
  order: 3;
  width: 100%;
}

.placholder-img {
  width: 100%;
  height: auto;
}

.filterlist_row {
  margin-bottom: 0.3rem;
}

.menu_img {
  height: 50px;
  width: 70%;
  object-fit: contain;
}

.menu_text {
  text-align: center;
}

.menu_link {
  display: flex;
  justify-content: space-around;
}

.component-collapse {
  position: relative;
  animation: slide-left 0.3s linear forwards;
}

@keyframes slide-left {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
    width: 0;
  }
}

.toggle-btn {
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 25px;
  cursor: pointer;
  right: 0;
}

.home-card {
  box-shadow: 1px 1px 9px 2px #c7c7c7ed;
  border: 1px solid rgb(0 0 0 / 27%);
}

.home-card:hover {
  box-shadow: 1px 1px 2px 1px #c7c7c7;
}

.table-taskbar {
  width: 100%;
  height: 40px;
  background: rgba(238, 238, 238, 0.808);
}

.action-items {
  display: inline-block;
  width: 50px;
  height: 100%;
  background: rgba(238, 238, 238, 0.808);
  text-align: center;
  justify-content: center;
  font-size: 20px;
  border: 1px solid rgb(161, 161, 161);
  transition: 0.2s linear;
}

.action-items:hover {
  background: rgb(192, 192, 192);
  cursor: pointer;
}

.home-container {
  margin-top: 30px;
}

.spinner-container {
  width: 100%;
  text-align: center;
  height: 70vh;
}

.progessBar {
  background-color: rgb(68, 212, 231);
  color: white;
  padding: 1px;
  margin-top: 10px;
}

.file-upload {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
}

.upbutton {
  width: 5rem;
  padding: 0.5rem;
  background-color: #2767e9;
  color: aliceblue;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}

.display-none {
  display: none;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.table-header {
  top: 0px;
  padding-bottom: 5px;
  display: flex;
}

.username-login {
  font-weight: 500;
  font-family: system-ui;
  color: #000;
  border-radius: 9px;
  margin: 8px;
  padding: 8px;
  border: 1px solid #bbb;
}

.username-login::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: green;
  display: inline-block;
}

.block-btn {
  width: 90px;
  margin-top: 10px;
}

.right-menu-items {
  font-size: 20px;
  padding: 5px 7px 9px 7px;
}

.circle-bg {
  background: #afafaf59;
  padding: 3px 7px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  box-shadow: 1px 0px 4px 1px #a7a7a7ba;
}

.circle-bg:hover {
  box-shadow: 1px 0px 2px 1px #a7a7a7;
}

.table-row-border {
  border-bottom: 0.6px solid rgb(175 175 175);
}

.table-row-border:hover {
  background-color: #c9c9c9e3;
}

.audit-container td {
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
  padding: 5px 8px 5px 0px;
  text-overflow: ellipsis;
}

.main-button {
  padding: 5px 5px;
  background-color: #6388c9;
  color: white;
}
.main-button-cancel {
  padding: 5px 5px;
  color: white;
}

.applyall {
  background-color: #6388c9;
  /* Green */
  color: white;
  border: 0.7px solid white;
}

.space {
  width: 4px;
  height: auto;
  display: inline-block;
}

.cloneNav {
  padding: 10px;
  margin: 20px auto;
  background-color: rgb(174, 213, 239);
}

.inlineedit {
  font-size: 10px;
  display: inline;
  white-space: nowrap;
  margin-right: 20px;
}

.applyover {
  display: inline;
  white-space: nowrap;
}

.inlineinternal {
  border: 0.2px solid white;
  font-size: 15px;
  align-items: center;
}
.inlineinternalSave {
  font-size: 15px;
  align-items: center;
}
.inlineinternalClose {
  border: 0.2px solid #c70000ed !important;
  font-size: 15px;
  align-items: center;
  background-color: #c70000 !important;
}

.main-button:hover {
  background-color: rgba(235, 235, 235, 0.829);
  color: black;
}

.page-control {
  margin-top: 10px;
  font-size: 14px;
  border: 0.7px solid rgb(43, 43, 43);
  padding: 3px;
  background-color: white;
  border-radius: 3px;
}

.page-number-input {
  width: 70px;
  border: none;
  border-bottom: 0.5px solid black;
}

.page-of {
  display: inline-block;
}

.prev-next-btn {
  display: inline-block;
  margin-left: 10px;
}

.second-control {
  display: inline-block;
  margin-left: 10px;
}

.modal-content {
  font-size: small;
}

.modal-xl {
  width: 95%;
}

/* LOAD BTN */

.load-btn {
  position: relative;
  cursor: not-allowed;
}

.load-btn::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  animation: loading;
  background-color: rgba(151, 195, 245, 0.753);
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes loading {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.top-toolbar button {
  margin: 5px;
  border: 1px solid rgb(94, 170, 241);
  background-color: #6388c9;
  color: white;
  border-radius: 3px;
}

.sticky-table-header {
  position: sticky;
  top: 0px;
  background-color: white;
}

.key-icon {
  margin-left: 20px;
  font-size: 19px;
}

.column-select-container {
  padding: 3px 10px;
  height: 40vh;
  overflow-y: auto;
}

.dropdown button {
  font-size: small;
  margin-top: 5px;
  margin-bottom: 5px;
}

.top-toolbar {
  display: flex;
  position: sticky;
  left: 0px;
  z-index: 1;
}

.ocr-toolbar {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0px;
  z-index: 1;
  padding: 4px 0;
  margin-right: 10px;
}

.top-toolbar .dropdown {
  width: auto;
  margin-right: 20px;
}

.top-toolbar p {
  width: auto;
  margin: 0;
  padding-top: 10px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: ' Import';
  display: inline-block;
  border-radius: 3px;
  padding: 0 0 0 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}

.custom-seed-file-input::before {
  content: ' Seed';
  display: inline-block;
  border-radius: 3px;
  padding: 0 0 0 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}

.custom-seed-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input:hover::before {
  border-color: black;
}

#file-input {
  padding-top: 0;
  width: 50px;
  cursor: pointer;
}

.right-toolbar {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.y-scroll {
  overflow-y: auto;
}

.toolbar-icon {
  font-size: large;
}

.error-log-btn {
  font-size: 1.1em;
  padding: 4px;
}

.sign-in-msg {
  font-size: 1.5rem;
  font-weight: bold;
}

.login-button-container {
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-ons {
  font-size: 1.2rem;
  background-color: #0d6efd;
  color: white;
}

.hidden-col {
  position: absolute;
  right: 0;
  width: 200px;
}

.header-snowflake-clone {
  color: white;
}

#tableauViz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.worldmap__figure-container {
  background: none !important;
}

.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.center-image img {
  max-width: 75%;
  height: 75%;
}

.card-container {
  position: relative;
  padding: 20px;
}

.ribbon {
  position: absolute;
  top: 20px;
  left: 21px;
  background: #239faf;
  color: white;
  padding: 3px 8px;
  z-index: 1;
  font-weight: bold;
  display: flex;
}

.ref-data-table-select .dropdown-toggle {
  background: white;
  color: black;
  width: 30rem;
  text-align: left;
}

@media (max-width: 991px) {
  .ref-data-table-select .dropdown-toggle {
    width: 90vw;
  }

  .table-select-dropdown-menu {
    max-height: 20vw;
  }
}

.ref-data-table-select .dropdown-toggle::after {
  display: none !important; /* replace with custom icon */
}

/* Fixed Height Drop down menu*/
.table-select-dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Compact Menu Items */
.table-select-dropdown-menu .dropdown-item {
  font-size: 12px !important;
  padding: 0 1rem;
}

/* Slim divider */
.table-select-dropdown-menu .dropdown-divider {
  margin: 0.2em 0;
}

/* SCROLL Bar */
body::-webkit-scrollbar,
.table-select-dropdown-menu::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track,
.table-select-dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(80, 80, 80, 0.438);
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb,
.table-select-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}
.menu_img_nps {
  width: 60%;
  object-fit: contain;
  height: 50px;
}

li.ql-mention-list-item {
  font-size: small;
  display: flex;
  height: 51px;
  line-height: 19px;
  align-items: center;
}

.dropdown-profile {
  padding: 10px;
  border-radius: 10px;
  margin: 2px 0px 4px 10px;
  border-width: 1px;
  border-style: solid;
  color: rgba(27, 59, 111);
  font-weight: 600;
}

.dropdown-profile .dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
  left: auto;
}
.profile_details #name {
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .dropdown-profile .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    right: 0;
  }
}
img {
  max-width: 100%;
}
.quill-image {
  max-width: 600px;
  max-height: 400px;
}
