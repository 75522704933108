.nps-dashboard-comments-conatiner .survey-accordion-summary {
    flex-direction: row-reverse;
    min-height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #f5f7fa;
}

.nps-dashboard-comments-conatiner .survey-accordion-summary:hover {
    background-color: #eef0f5;
}

.nps-dashboard-comments-conatiner .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 16px;
}

.nps-dashboard-comments-conatiner .header-left {
    display: flex;
    align-items: center;
    gap: 16px;
}

.nps-dashboard-comments-conatiner .survey-title {
    font-weight: 600;
    color: #2c3e50;
}


.nps-dashboard-comments-conatiner .response-chip {
    background-color: #e3f2fd;
    color: #1976d2;
    font-weight: 500px;
    padding-left: 12px;
    padding-right: 12px;
}


.nps-dashboard-comments-conatiner .accordion-content {
    padding: 0;
    max-height: 500px;
    overflow-y: auto;
}



.nps-dashboard-comments-conatiner .accordion-content::-webkit-scrollbar {
    width: 6px;

}


.nps-dashboard-comments-conatiner .accordion-content::-webkit-scrollbar-track {
    background: #f1f1f1;

}


.nps-dashboard-comments-conatiner .accordion-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

}


.nps-dashboard-comments-conatiner .accordion-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.nps-dashboard-comments-conatiner .content-paper {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.nps-dashboard-comments-conatiner .response-list {
    padding: 16px;
}

.nps-dashboard-comments-conatiner .response-item {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
}

.nps-dashboard-comments-conatiner .response-text {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
}


.nps-dashboard-comments-conatiner .response-divider {
    margin-top: 8px;
}


.nps-dashboard-comments-conatiner .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.23);
}

.nps-dashboard-comments-conatiner .page-size-selector {
    display: flex;
    align-items: center;
    gap: 8px;
}


.nps-dashboard-comments-conatiner .loading-container {
    padding: 24px;
}

.nps-dashboard-comments-conatiner .skeleton-item {
    margin-bottom: 16px;
}
