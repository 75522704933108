@import '../fnf_global.scss';
@import '../fnf_variables.scss';

.grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0rem;
  background-color: $BodyBackgroundColor;

  .MuiPaper-root {
    thead tr th {
      white-space: nowrap;
    }
    .expanded {
      width: 1000px;
    }

    tbody tr td {
      white-space: initial !important;
    }
  }

  .filterrow {
    background-color: $QuadraticColor;
    padding: 0.5rem;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 5px 8px;
      border: none;
      background-color: $SecondaryColor;
      color: white;
      margin-left: 5px;
    }

    .eachfilter {
      margin-right: 1rem;

      label {
        font-size: 0.8rem;
        font-weight: 500;
        margin-right: 8px;
      }
      input,
      select {
        outline: none;
        border: 1px solid #6b6b6b;
        color: #6b6b6b;
        padding: 4px 8px;
        min-width: 100px;
      }
    }
  }
  .bar1 {
    background-color: $KHBlue;
    display: flex;
    color: $PrimaryTextColor;
    padding: 0.5rem;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: space-between;

    h4 {
      flex: 1;
      text-align: center;
    }
  }
  .MuiPaper-root {
    background: none;
    margin-top: 0.5rem;
    flex: 1;

    .css-41abqd-MuiTableContainer-root {
      max-height: none;
    }
    .danger {
      background-color: $KHRed;
    }
    .safe {
      background-color: $DotheRightThing;
    }

    thead tr th {
      background-color: $MiamiBlue;
      color: $TableTextColor;
      font-size: 0.75rem;
      padding: 5px 1rem;
      text-align: center;
      border-right: 1px solid white;
      line-height: 16px;
    }
    tbody tr td {
      font-size: 0.75rem;
      text-align: center;
      padding: 12px;
      white-space: initial;
      span,
      button {
        border: none;
        color: $MiamiBlue;
      }
    }
  }
}
