.ops_card {
    transition: box-shadow 0.3s, transform 0.3s;
    position: relative;
    background-color: #d9dcdf !important;
    padding: 10px;
    margin-bottom: 600px;
    border-radius: 25px;
}

.ops_card:hover {
    box-shadow: 0 4px 6px rgba(0,0,0,0.2);
    transform: translateY(-2px);
}

.kudos_card {
    transition: box-shadow 0.3s, transform 0.3s;
    padding: 10px;
    margin-bottom: 600px;
    border-radius: 25px;
    display: grid;
    grid-template-columns: 10% 90%;
    width: 100%;
    border-left:5px solid #52489C;
    transition: all 1s ease;
}
.submitter-form .react-quill-badge > .ql-container > .ql-editor{
    margin: 0;
    height: 43px;
}
.kudos_card > #kudo_icon {
    grid-column: 1;
    grid-row: 1 / span 3;
    display: flex;
    align-items: center;
    margin: 5px;
    color: #52489C;
    font-size: 28px;
}

.kudos_card > #kudo_message {
    grid-column: 2;
    display: grid;
    margin: 10px 0 10px 0;
    font-style: italic;
    font-size: 14px;
    color: #52489C;
}

.kudos_card > #kudo_sender {
    text-align: right;
    font-size: 12px;
    color: gray;
    padding:2px;
}

.kudos_card > #kudo_date {
    text-align: right;
    font-size: 10px;
    color: gray;
}

.kudos_card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);

    #kudo_icon{
    font-size: 30px;
    }
}

.kudos-jumbotron {
    border-radius: 6px;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    padding: 1em;
}

.kudos-jumbotron-heading {
    font-weight: 600;
}

.kudos-jumbotron-list {
    margin-bottom: 0;
}

.kudos-submit-button {
    width: fit-content !important;
}

.send-kudos-header {
    background-color: #1D3B6D;
    color: #fff;
}

.send-kudos-header .btn-close {
    background-color: #fff;
}
