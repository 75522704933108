@import '../../../fnf_variables.scss';

.screen1sidebar {
  .rightheader {
    span {
      color: $MiamiBlue;
      font-size: 1rem !important;
    }
    button {
      background-color: $TertiaryColor;
      border: none;
      padding: 8px;
      color: $TertiaryTextColor;
    }
  }
  .rulesrow {
    margin-top: 16px;
    .title {
      color: $MiamiBlue;
      font-size: 1.2rem !important;
    }
    .rule {
      font-size: 0.8rem !important;
      font-weight: bold;
    }
  }
  .objectmappedbtn {
    background-color: $DotheRightThing;
    padding: 8px;
    color: white;
    border: none;
    margin-right: 16px;
  }
  .podrequestedbtn {
    background-color: $DemandDiversity;
    padding: 8px;
    color: white;
    border: none;
    margin-right: 16px;
  }
  svg {
    color: gray;
    font-size: 1.3rem;
  }
  .editbtn {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.6rem;
    }
  }
  .actionbtns {
    button {
      padding: 8px;
      margin: 8px;
      background-color: $TertiaryColor;
      color: $TertiaryTextColor;
      border: none;
    }
    .active {
      background-color: $SecondaryColor;
      color: $SecondaryTextColor;
    }
    .addbtn {
      display: flex;
      align-items: center;
      background-color: $SecondaryColor;
      color: $SecondaryTextColor;
      svg {
        font-size: 1rem;
        margin-left: 4px;
        color: $TertiaryTextColor;
      }
    }
  }

  .bottomactions {
    display: flex;
    justify-content: space-between;
  }
}
