.chapter-container {
    background-color: #F0F3F8;
    min-height: 100vh;
    padding: 1.5em;
}
.dashboard-flex-column {
    flex-direction: column;
}
.chapter-quotes {
    font-weight: 700;
    display: block;
    text-align: center;
    margin-top: 1em;
}
.chapter-image {
    width: fit-content;
    display: flex;
    align-self: center;
}
.chapter-title {
    color: #071437;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.chapter-lead {
    text-decoration: underline!important;
    text-transform: capitalize;
    display: inline-block;
    margin-left: 0.25em;
    color: #000;
}
.chapter-action {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1em;
}
.chapter-label {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
}
.chapter-label span {
    color: #78829d;
}
.chapter-info-contatiner {
    background-color: #F0F3F8;
    min-height: 100vh;
    padding: 1.5em;
}
.chapter-info-heading {
    color: #0F1A38;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}
.chapter-info-desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-bottom: 1px solid lightgray;
    padding-bottom: 1em;
}
.chapter-info-contatiner h2,.chapter-info-contatiner h3, .chapter-info-contatiner .h4 {
    font-weight: 500;
    color: #071437;
}
.chapter-info-contatiner h2 {
    font-size: 1.75rem;
    line-height: 2rem;
}
.chapter-info-contatiner h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
}
.chapter-info-contatiner li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.chapter-info-contatiner .h4 {
    font-size: 2rem;
    line-height: 2.25rem;
}
.chapter-info-contatiner p {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}
.chapter-info-contatiner .tab_component .nav-link.active {
    border-bottom: 3px solid #295CAD;
    color: #295CAD;
}
.chapter-info-contatiner strong {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
}
.chapter-info-contatiner .panel-heading {
    color: #252f4a;
    font-size: 1.275rem;
    line-height: 1.5rem;
    display: block;
    font-weight: 600;
}
.chapter-info-contatiner .panel-content {
    color: #252f4a;
    font-size: 1rem;
    line-height: 1.5rem;
}
.chapter-info-contatiner .tab_component .nav-link {
    color: #595959;
    font-weight: 600;
}
.chapter-action-button {
    width: fit-content!important;
    text-transform: capitalize!important;
    color: #295CAD!important;
    border-color: #295CAD!important;
    font-size: 14px!important;
    line-height: 22px!important;
    border-radius: initial!important;
    height: 27px;
}
.chapter_at_khc_heading {
    color: #0F1A38;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0.5em 0;
}
