@import '../../../fnf_variables.scss';

.screen1 {
  .filterrow {
    background-color: $QuadraticColor;
    padding: 0.5rem;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;

    .eachfilter {
      margin-right: 1rem;

      label {
        font-size: 0.8rem;
        font-weight: 500;
        margin-right: 8px;
      }
      input,
      select {
        outline: none;
        border: 1px solid #6b6b6b;
        color: #6b6b6b;
        padding: 4px 8px;
        min-width: 100px;
        font-size: 0.9rem;
      }
    }
  }

  .bar1 {
    background-color: $KHBlue;
    display: flex;
    color: $PrimaryTextColor;
    padding: 0.5rem;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: space-between;

    h4 {
      flex: 1;
      text-align: center;
      font-size: 1.3rem;
    }
  }

  .bar1data {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;

    .eachdata {
      border: 2px solid $QuadraticColor;
      padding: 8px 0;
      width: 12%;
      text-align: center;

      h4 {
        margin-top: 8px;
      }
      label {
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }
  }
}
