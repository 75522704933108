.glossary-term-view-container {
  padding: 20px;
}

.glossary-term-header-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
}

.glossary-term-edit-icon {
  cursor: pointer;
}

.glossary-term-khc-logo {
  position: absolute;
  right: 1%;
  width: 12%;
  height: auto;
}

.glossary-term-info-container {
  text-align: center;
  align-items: center;
}

.glossary-term-definition-container {
  text-align: left;
}
/* Override some styles */
.glossary-term-definition-container .ql-editor {
  overflow: visible;
  height: auto;
}
/* Override a href style */
.glossary-term-definition-container a {
  color: blue;
  text-decoration: underline;
}

/* Some of these classes are for overriding user submitted html classes */
.language-python {
  overflow: initial;
  width: -webkit-fill-available;
}

.undefined {
  overflow: initial;
  width: -webkit-fill-available;
}

.glossary-term-border-line {
  border-width: 3px;
}
