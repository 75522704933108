.tab_component {
    border-bottom: 1px solid #7E8299;
}
.tab_component .nav-link {
    color: #7E8299;
    border-bottom: 1px solid transparent;
    border-radius: 0;
}
.tab_component .nav-link.active {
    background-color: #fff;
    color: #3699FF;
    border-bottom: 1px solid #3699FF;
}
.tab-content {
    margin: 1em;
}
