.card-col {
    flex-basis:32%;
    border: none;
    margin-bottom: 10px;
}
.idea_card {
    background-color: #fff;
    border-radius: .75rem;
    margin-bottom: 10px;
    transition: all 0.4s ease;
}
.idea_card:hover {
    transform: translateY(-10px);
    transition: all 0.4s ease;
}
.border-warning {
    border: 1px solid #f6b100;
}
.border-default {
    border: 1px solid #636674;
}
.border-danger {
    border: 1px solid #f8285a;
}
.border-primary {
    border: 1px solid #1b84ff;
}
.border-info {
    border: 1px solid #7239ea !important; 
    /* added important to take over the bootstap border as that is also coming with important */
}
.border-success {
    border: 1px solid #17c653;
}
border-bottom-none {
    border-bottom: none;
}
.card-height {
min-height: 100%;
}
.card-height-chapter-nps {
    min-height: 95%;
}
.status-bar{
    display: flex;
    font-size: 12px;
    bottom:0;
    justify-content: center;
    color: white;
    padding: 2px;
}
.status-bar-primary{
    background-color: #1b84ff;
}
.status-bar-info{
    background-color: #7239ea;
}
.status-bar-warning{
    background-color: #f6b100;
}
.status-bar-success{
     background-color: #17c653;
}
.status-bar-danger {
    background-color: #f8285a;
}
.status-bar-deprecate {
    background-color: #636674;
}
