@import '../../../fnf_variables.scss';

.screen05sidebar {
  .compliantbtn {
    background-color: $KHRed;
    padding: 8px;
    color: white;
    border: none;
    margin-right: 16px;
  }
  .finemappedbtn {
    background-color: $DotheRightThing;
    padding: 8px;
    color: white;
    border: none;
    margin-right: 16px;
  }
  svg {
    color: gray;
    font-size: 1.3rem;
  }
  .editbtn {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.6rem;
    }
  }
  .actionbtns {
    button {
      padding: 8px;
      margin: 8px;
      background-color: $TertiaryColor;
      color: $TertiaryTextColor;
      border: none;
    }
    .active {
      background-color: $SecondaryColor;
      color: $SecondaryTextColor;
    }
    .addbtn {
      display: flex;
      align-items: center;
      background-color: $SecondaryColor;
      color: $SecondaryTextColor;
      svg {
        font-size: 1rem;
        margin-left: 4px;
        color: $TertiaryTextColor;
      }
    }
  }

  .bottomactions {
    display: flex;
    justify-content: space-between;
  }

  .orderdetails {
    padding: 1rem;

    > h4 {
      color: $MiamiBlue;
      margin-bottom: 0.5rem;
    }

    .detailsinfo {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;

      .content {
        margin-top: 0.5rem;
        width: 46%;

        > div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.3rem;

          > span {
            font-size: 0.9rem;
            text-align: right;
          }

          .title {
            margin-right: 2rem;
            font-weight: bold;
            text-align: left;
          }
        }
      }

      .onlyleft {
        width: 100%;

        > div {
          span {
            width: 30%;
            text-align: center;
            margin-right: 2px;
          }
          .heading {
            font-weight: bold;
            background-color: $QuadraticColor;
            padding: 0.3rem 0.5rem;
          }
          .title {
            text-align: left;
          }
          .remark {
            flex: 1;
            color: $KHRed;
          }
        }
      }
    }
  }
}
