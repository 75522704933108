
.line-chart-tooltip-wrapper{
  z-index: 1000;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  min-height: 200px; 
  min-width: 200px;
  overflow-y: auto; 
  scroll-behavior: smooth;
  padding: 12px; 

}

.line-chart-tooltip-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333333;
  position: sticky; 
  top: 0;
  background: white;
  padding: 0.5rem 0;
}

.line-chart-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.line-chart-tooltip-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.line-chart-tooltip-item-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.line-chart-tooltip-color-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
}

.line-chart-tooltip-item-content {
  font-size: 0.875rem;
}

.line-chart-tooltip-divider {
  margin: 0.5rem 0;
  border-top: 1px solid #eeeeee;
}
.line-chart-chart-legend {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  justify-content: center;
  margin-bottom:-5; 
  padding: 8px 0;  
}

.line-chart-legend-item {
  display: flex;
  align-items: center;  
  gap: 0.5rem;
}

.line-chart-legend-color-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.line-chart-legend-label {
  font-size: 0.875rem;
  color: #333333;
}

.line-chart-axis-label {
  font-size: 1.00rem;
  fill: #666666;
}

.line-chart-chart-grid line {
  stroke: #e0e0e0;
}


.line-chart-chart-line {
  transition: opacity 0.2s ease;
}

.line-chart-chart-line:hover {
  opacity: 0.8;
}


.line-chart-chart-dot:hover {
  r: 6;
}
