.survey-admin-card {
  background-color: #01536c !important ;
}

.npsAdminBody > tr > td:nth-child(1) {
  width: 2%;
}
.admin-nps-modal > div {
  width: 85% !important;
  max-width: 100% !important;
}
