@import '../../../fnf_variables.scss';

.stepsbar {
  border: 2px solid $QuadraticColor;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    border: none;
    font-size: 0.9rem;
  }

  .active {
    background-color: $SecondaryColor;
    color: $SecondaryTextColor;
  }

  .inactive {
    background-color: $TertiaryColor;
    color: $TertiaryTextColor;
  }
}
