.detractors-0-6-child {
  position: absolute;
  margin: 0 !important;
  top: 0px;
  left: 0px;
  height: 2160px;
  width: 100%;
  object-fit: contain;
  z-index: 0;
}

.button-go-back {
  position: absolute;
  left: 0.5rem;
  top: 5rem;
  background-color: #e6e6e6;
  /* background-color: rgba(51, 51, 51, 0.05); */
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 5px;
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-go-back:hover {
  background-color: #6c757d;
  color: #fff;
}

.customer-experience-survey {
  margin: 0;
}

.global-business-service {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
}

.customer-experience-survey-container {
  flex: 1;
  position: relative;
}

.customer-experience-survey-glo-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0px;
}

.thank-you-for {
  width: 100%;
  position: relative;
  font-weight: 600;
  display: inline-block;
  height: 24px;
  flex-shrink: 0;
}

.were-looking-for-your-feedbac-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 140px;
  font-size: 16px;
  color: #595959;
}

.text-elementsmessaging-banner {
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  font-size: 20px;
  color: #295cad;
}

.form-parant-div{
    border-radius: 8px 8px 0px 0px;
}

.user-form-heading {
    display: flex;
    width: 100%;
    color: #000;
    border: none;
    padding: 15px;
    background-color: #f5f9ff;
}

.frame-parent {
    background-image: url('../../../../media/nps_media/BG_2.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px 8px 0px 0px;
}

.account-to-report {
  align-self: stretch;
  position: relative;
}

.cash-payroll {
  position: absolute;
  top: calc(50% - 7.5px);
  left: 15.09%;
  font-weight: 600;
}

.chips1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 6px;
  background-color: #295cad;
  overflow: hidden;
}

.chips {
  width: 106px;
  position: relative;
  height: 31px;
}

.cash-payroll1 {
  position: absolute;
  top: calc(50% - 7.5px);
  left: 20.75%;
  font-weight: 600;
}

.cash-payroll2 {
  position: absolute;
  top: calc(50% - 7.5px);
  left: 13.21%;
  font-weight: 600;
}

.chips-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 43px;
  font-size: 12px;
  color: #fff;
}

.based-on-your-past-interaction-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 16px;
  color: #595959;
}

.h2-headline {
    align-self: stretch;
    position: relative;
    font-weight: 600;
    font-size: 18px;
}

.b {
  position: relative;
  line-height: 22px;
  width: 100%;
}

.input-0 {
  width: 60px;
  border-radius: 5px 0px 0px 5px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9.5px 14.5px;
  box-sizing: border-box;
}

.txtarea>textarea {
    border: none;
    width: 100%;
    height: 240px;
    font-size: 18px;
    font-weight: 400;
}

.row-child {
  align-self: stretch;
  width: 1px;
  position: relative;
  border-right: 1px solid #295cad;
  box-sizing: border-box;
}

.h2-headline-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
}

.accordion {
  width: 100%;
}

.nps-box {
    width: 100%;
    border-radius: 6px;
    background-color: #f5f9ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    box-sizing: border-box;
}

.text-field {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  height: 240px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: #595959;
}

.type-here {
  flex: 1;
  position: relative;
  letter-spacing: -0.15px;
  line-height: 20px;
  border: none;
  resize: none; /* Disable resizing */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px; /* Reset padding */
}

.text-form {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 36px;
}

.text-form-wrapper {
  width: 100%;
  border-radius: 6px;
  background-color: #f5f9ff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 32px;
  box-sizing: border-box;
}

.nps-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  font-size: 20px;
}

.questions {
  width: 100%;
  border-radius: 8px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  box-sizing: border-box;
  gap: 32px;
}

.check-box-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  border-radius: 3px;
  border: 1px solid #595959;
  box-sizing: border-box;
}

.check-box {
  width: 20px;
  position: relative;
  height: 20px;
}

.do-you-wish {
  position: relative;
}

.check-box-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #595959;
  cursor: pointer;
}

.check-box-input {
    margin-right: 8px;
    accent-color: #295cad;
    /* For custom checkbox color */
}

.check-box-label input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

.text-elementsmessaging-banner1 {
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 88px;
}

.done {
  position: relative;
  line-height: 18px;
  font-weight: 600;
}

.center-view-form-btn {
    gap: 15px;
}

.submit-btn {
    width: 340px;
    border-radius: 5px;
    background-color: #8dc881;
    height: 46px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    border: none;
}

.submit-btn:hover {
    background-color: #73a369;
}

.Decline-btn {
    width: 340px;
    border-radius: 5px;
    background-color: #c82727;
    height: 46px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    border: none;
}

.Decline-btn:hover {
    background-color: #811111;
}

.Skip-btn {
    width: 340px;
    border-radius: 5px;
    border: 1px solid #295cad;
    background-color: transparent;
    font-size: 20px;
    color: #295cad;
    height: 46px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    box-sizing: border-box;
    font-size: 16px;
    cursor: pointer;
}

.Skip-btn:hover {
    background-color: #295cad;
    color: #fff;
}

.text-elementsmessaging-banner-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 16px;
  color: #595959;
}

.form {
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: #f5f9ff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px;
    box-sizing: border-box;
    gap: 32px;
    z-index: 1;
}

.detractors-0-6 {
    background-image: url('../../../../media/nps_media/bg_1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #e6e6e6;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 80px;
    text-align: center;
    font-size: 28px;
    color: #0f1a38;
}

/* .info-anonymous{
    padding: 8px 88px;
    font-size: 16px;
    font-weight: 400;
} */
