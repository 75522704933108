@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes flowingColors {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.nps-analytics-dashboard .nps-analytics-container {
  min-width: 50vw;
  margin: 0;
  padding: 25px;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background: linear-gradient(45deg, #f3f4f6, #ffffff);
  background-size: 200% 200%;
}

.nps-analytics-dashboard .summary-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
  padding: 20px 10px;
}

.nps-analytics-dashboard .summary-card {
  flex: 1;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  overflow: hidden;
  isolation: isolate;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

/* Initial load animations */
.nps-analytics-dashboard .summary-card:nth-child(1) {
  animation: fadeInUp 800ms 100ms both;
}

.nps-analytics-dashboard .summary-card:nth-child(2) {
  animation: fadeInUp 800ms 200ms both;
}

.nps-analytics-dashboard .summary-card:nth-child(3) {
  animation: fadeInUp 800ms 300ms both;
}

.nps-analytics-dashboard .summary-card:nth-child(4) {
  animation: fadeInUp 800ms 400ms both;
}

.nps-analytics-dashboard .summary-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg,
      rgba(66, 133, 244, 0.1),
      rgba(66, 133, 244, 0.2),
      rgba(66, 133, 244, 0.1),
      rgba(219, 68, 55, 0.1),
      rgba(244, 180, 0, 0.1),
      rgba(15, 157, 88, 0.1));
  background-size: 400% 400%;
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0;
  transform: rotate(0deg);
}

.nps-analytics-dashboard .summary-cards:hover .summary-card:not(:hover) {
  transform: scale(0.95);
  filter: brightness(0.9);
  opacity: 0.8;
}

.nps-analytics-dashboard .summary-card:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  z-index: 1;
  background: rgba(255, 255, 255, 0.95);
}

.nps-analytics-dashboard .summary-card:hover::before {
  opacity: 1;
  animation: flowingColors 8s linear infinite;
}

.nps-analytics-dashboard .summary-card-icon {
  position: relative;
  z-index: 1;
  color: #4285F4;
  transition: transform 300ms ease;
}

.nps-analytics-dashboard .summary-card:hover .summary-card-icon {
  transform: scale(1.1);
}

.nps-analytics-dashboard .summary-card h4 {
  position: relative;
  z-index: 1;
  margin: 15px 0;
  color: #666;
  transition: all 300ms ease;
}

.nps-analytics-dashboard .summary-card:hover h4 {
  color: #4285F4;
  transform: scale(1.05);
}

.nps-analytics-dashboard .summary-card p {
  position: relative;
  z-index: 1;
  font-size: 1.8em;
  font-weight: bold;
  color: #4285F4;
  margin: 0;
  transition: all 300ms ease;
}

.nps-analytics-dashboard .summary-card:hover p {
  transform: scale(1.05);
}

.nps-analytics-dashboard .question-card {
  margin-bottom: 30px;
  padding: 25px;
  border: 1px solid rgba(224, 224, 224, 0.5);
  border-radius: 15px;
  min-height: 12rem;
  background: rgba(255, 255, 255, 0.95);
}


.nps-analytics-dashboard .average-answer-section {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 300ms ease;
  margin-bottom: 20px;
  border: 1px solid transparent;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.nps-analytics-dashboard .average-answer-section:hover {
  transform: translateX(4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-color: rgba(66, 133, 244, 0.2);
}

.nps-analytics-dashboard .average-answer-value {
  font-weight: 500;
  font-size: 1em;
  color: #2c3e50;
  transition: all 300ms ease;
}

.nps-analytics-dashboard .average-answer-section:hover .average-answer-value {
  color: #4285F4;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .nps-analytics-dashboard .summary-cards {
    flex-direction: column;
    padding: 10px;
  }

  .nps-analytics-dashboard .summary-card {
    margin: 10px 0;
  }

  .nps-analytics-dashboard .summary-card:hover {
    transform: scale(1.05);
  }

  .nps-analytics-dashboard .summary-cards:hover .summary-card:not(:hover) {
    transform: scale(0.98);
  }
}


.nps-analytics-dashboard .back-btn {
  background-color: #003366;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.nps-analytics-dashboard .view-comments-btn {
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.nps-analytics-dashboard .render-visualization-box {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.nps-analytics-dashboard .survey-icon {
  margin-right: 10px;
  color: #6A5ACD;
  font-size: 40px;
}



.nps-analytics-dashboard .export-button:hover {
  background-color: #009148 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.nps-analytics-dashboard .export-button:disabled {
  background-color: #84c7a6 !important;
}


.nps-analytics-dashboard .comments-export {
  background-color: #00aa55;
  border: none;
  font-weight: 500px;
  padding: 8px 16px;
  border-radius: 8px;
  min-width: 120px;
  height: 36px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}


.export-btn-container {
  position: absolute;
  right: 3rem;
}

.export-loading {
  /* color: white; */
  margin-right: 8px;
}

.export-comments-icon {
  margin-right: 8px;
  font-size: 20px;
}
