.btnSnowflake {
  background-color: #6388c9;
  border-color: #6388c9;
  font-size: 13px;
  padding: 4px;
}
.btnSnowflake:hover {
  color: #000;
  background-color: #fff;
  border-color: #6388c9;
}
.header-snowflake-clone {
  display: flex;
  width: 100%;
  padding: 8px;
}
.sticky-table-header-sf {
  z-index: 1;
  top: -1px;
}
.top-toolbar-sf {
  display: flex;
  left: 0;
  position: sticky;
  z-index: 1;
}

.hidden-col-sf {
  position: relative;
  right: 0;
  width: auto;
  padding: 6px;
  font-size: 14px;
}
