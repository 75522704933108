@import '../fnf_global.scss';
@import '../fnf_variables.scss';

.rulepage {
  display: flex;
  flex-direction: column;
  background-color: $BodyBackgroundColor;

  .ruledetails {
    padding: 16px;

    #seemorebtn {
      background: none;
      border: none;
      color: $KHBlue;
      font-weight: bold;
      margin-top: 16px;
    }

    .ruleinfo {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      .seemore {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .eachruleinfo {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 31%;
        margin-top: 16px;
        margin-bottom: 4px;
        padding: 0 8px;
        h4 {
          margin-right: 16px;
          width: 100%;
          font-size: 0.85rem;
          font-weight: bold;
        }
        input,
        textarea,
        select {
          flex: 1;
          padding: 8px;
          width: 100%;
          margin-top: 10px;
          box-sizing: border-box;
        }
        .radiowrapper {
          display: flex;
          margin-top: 10px;
        }
        input[type='radio'] {
          margin: 0 8px 0 16px;
        }
      }
    }
    .actionitems {
      padding-left: 0;
      svg {
        color: red;
        &:hover {
          cursor: pointer;
        }
      }

      button {
        margin-top: 32px;
      }
      #backbtn {
        margin-top: 0;
        margin-bottom: 16px;
      }
    }
  }

  .bar1 {
    background-color: $KHBlue;
    display: flex;
    color: $PrimaryTextColor;
    padding: 0.5rem;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: space-between;

    h4 {
      flex: 1;
      text-align: center;
    }
  }
}

.loaderoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: black, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
