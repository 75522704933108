.glossary-pending-container {
  padding: 10px;
}

.glossary-title-container {
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  align-items: center; /* Center children horizontally */
  text-align: center;
}

.glossary-custom-table {
  background-color: #f8f9fa;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.glossary-back-button {
  display: inline-block;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
}

.glossary-back-button-icon {
  margin-right: 5px;
}
