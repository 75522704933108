.css-1w3l1ns {
  background-color: #ffd700;
  border-radius: 50px;
  color: #000;
  padding: 6px;
}
.block-btn1 {
  width: 60px !important;
  margin-top: 10px;
}
.table-header-badge {
  top: 0px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}
.icons {
  margin: 4px;
  border-radius: 50px;
}
tr:nth-child(even) {
  background: #f7f7f79e;
}
.badge-btn {
  background-color: rgb(51 51 51 / 11%);
  border: 1px solid #cfcfcf;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 3px;
  padding: 3px 11px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.round {
  border-radius: 3px;
  background: #e9e9e9;
  font-weight: 500;
}


.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  max-width: 100%;
  justify-content: flex-start !important;
}
.badge-table-container {
  min-width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.badge-table-container .badge-table {
  table-layout: auto; 
}

.nav-btn {
  width: none !important;
  margin: 8px;
}
.nav-menu {
  background-color: #fff;
}

.active-btn {
  padding: 5px 5px;
  background-color: #0b5ed7;
  color: #fff;
  border: 1px solid #0b5ed7;
}
.badge-nav-btn {
  padding: 5px 5px;
  background-color: #1d3b6d;
  border: 1px solid #1d3b6d;
}
.badge-nav-btn:hover {
  padding: 5px 5px;
  background-color: #cfcfcf;
  color: #000;
  border: 1px solid #cfcfcf;
}
.chat {
  display: flex;
  flex-direction: row;
  margin: 5px;
  padding: 5px;
}
.chat-msg {
  background: #9cd6ff;
  border-radius: 30px;
  padding: 5px;
  margin: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.sent-by {
  font-size: 14px;
  margin: 5px;
  float: left;
  justify-content: left;
  display: flex;
  color: #545454;
  font-style: italic;
}
.main-chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.main-chat:nth-child(even) {
  justify-content: flex-end;
  align-items: flex-end;
}
.datetime {
    font-size: 12px;
    margin: 5px;
    float: left;
    justify-content: center;
    display: flex;
    color: #545454;
}
.badgebody > tr > td:first-child {
  width: 3%;
}
.badgebody > tr > td:last-child {
  text-align: left;
  white-space: break-spaces;
}
.mybadgesBody > tr > td:nth-child(4) {
  text-align: left;
  white-space: break-spaces;
}
.mybadgesBody > tr > td:nth-child(5) {
  text-wrap: balance;
  text-align: left;
  white-space: break-spaces;
}
.mybadgesBody > tr > td:nth-child(1) {
  width: 2%;
}
.mybadgesBody > tr > td:nth-child(6) {
  text-wrap: balance;
  text-align: left;
  white-space: break-spaces;
}
.pendingbadgesBody > tr > td:nth-child(5) {
  text-align: left;
  white-space: break-spaces;
}
.pendingbadgesBody > tr > td:nth-child(7) {
  text-align: left;
  white-space: break-spaces;
}
.pendingbadgesBody > tr > td:nth-child(6) {
  text-wrap: balance;
}
.pendingbadgesBody > tr > td:nth-child(1) {
  width: 2%;
}
.badgeHead {
  display: flex;
  justify-content: center;
  color: #1d3b6d;
  font-size: 1.7em;
  font-weight: 800;
  margin: 10px;
  font-family: system-ui;
}
.muiHead {
  font-size: 11px !important;
  line-height: 0px !important;
  letter-spacing: 0 !important;
  padding: 11px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
.muiTdata {
  font-weight: 500 !important;
  font-size: 17px !important;
  border-bottom: none !important;
  letter-spacing: 0 !important;
  padding: 2px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
.gold {
  color: #ffd700 !important;
}
.silver {
  color: #c0c0c0 !important  ;
}
.bronze {
  color: #cd7f32 !important;
}
.summary{
  width: 100% !important;
}
.badge-map{
  height: inherit;
  width: 40% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.worldmap__figure-container{
  margin: 0;
}
.css-rorn0c-MuiTableContainer-root {
  width: 60% !important;
}
.leftable {
  border: 1px solid #ddd;
  float: left;
  margin: 0px 15px 10px 15px;
  padding: 20px;
  width: 95% !important;
}
.muiTdata {
  text-align: center;
  justify-content: center;
}
.secondtable {
  border: 1px solid #ddd;
  /* float: left; */
  margin: 0px 15px 0px 15px;
  /* padding: 3px; */
  /* padding: 20px; */
  width: 95% !important;
}

/* .thirdtable {
  width: 30% !important;
  float: left;
  padding: 20px;
  border: 1px solid rgb(221, 221, 221);
  margin-left: 20px;
} */

.count-main {
  display: flex;
  justify-content: center;
}
.inner-count {
  align-items: flex-end;
  display: flex;
  font-size: 15px;
}
.Mui-expanded .MuiAccordionSummary-gutters {
  min-height: 0px !important;
  height: 28px !important;
}
.textModal {
  color: #000000;
  font-size: 17px;
  font-family: sans-serif;
  margin: 1px;
  display: flex;
  align-items: center;
  text-align: left;
}

.badgeInner-gold {
  display: flex;
  margin: 10px;
  padding: 8px;
  width: 95%;
  background: #f9d5174d;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  align-items: center;
}
.badgeInner-silver {
  display: flex;
  margin: 10px;
  padding: 8px;
  width: 95%;
  background: #cdcdcd4d;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  align-items: center;
}
.badgeInner-bronze {
  display: flex;
  margin: 10px;
  padding: 8px;
  width: 95%;
  background: #fb902638;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  align-items: center;
}
.badgeInner-bronze:hover {
  transform: translateY(-10px);
}
.badgeInner-gold:hover {
  transform: translateY(-10px);
}
.badgeInner-silver:hover {
  transform: translateY(-10px);
}
/* ::selection {
  background: #ff676d;
  color: #fff;
} */
.containerBadge {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.main-card {
  max-width: 40%;
  transition: 1s;
  margin-right: 30px;
}

.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.cards .card {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
}
.main-card .cards .card:hover {
  transform: translateX(10px);
}
.edit-icon {
  background: none;
  border: none;
  border-radius: 5px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.edit-icon:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.cards .card .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cards .card .content .img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  padding: 3px;
  background: #7d85eb;
  margin-bottom: 14px;
}
.card .content .img img {
  height: 100%;
  width: 100%;
  border: 3px solid #ffff;
  border-radius: 50%;
  background: #d6d9ff;
  object-fit: cover;
}
.card .content .name {
  font-size: 15px;
  font-weight: 500;
}
.card .content .job {
  font-size: 16px;
  color: #000;
}
.card .content .media-icons {
  margin-top: 10px;
  display: flex;
}
.media-icons a {
  text-align: center;
  line-height: 33px;
  height: 35px;
  margin: 0 4px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.containerBadge .button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
}
.button label {
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #fff;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.button label.active {
  width: 35px;
}

.details {
  display: flex;
  margin: 5px;
  flex-direction: column;
  text-align: left;
  /* width: 100%; */
  font-size: 15px;
}

.details-request {
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 15px;
  /* font-weight: 500; */
  background: #dde7f9cc;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  margin-bottom: 13px;
}
.details-request:hover {
  transform: translateY(-10px);
}
.details-inner {
  display: flex;
}
.details-text {
  margin-left: 5px;
  max-width: 100%;
  white-space: break-spaces;
  overflow-wrap: normal;
  display: flex;
}

.font-text {
  font-size: 13px;
  padding:2px;
}

.react-quill-badge > .ql-container > .ql-editor {
  height: 47px;
  margin: 10px;
  width: 250px;
}
.react-quill-badge > .ql-container.ql-snow {
  border-radius: 7px;
}


.pod-name {
  text-decoration: underline;
  color: #1d3b6d;
  cursor: pointer;
}

.pod-details-inner{
  display: flex;
}

.pod-details-inner p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pod-details-inner p span {
  margin: 2px;
  width: fit-content;
}

.user-profile-tabs .nav-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #007bff;
}

.user-profile-tabs .nav-item {
  margin-bottom: -1px;
}

.user-profile-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  color: #555758;
  padding: 10px 45px;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  background-color: #cbd0d4;
  flex: 1;
  text-align: center;
}

.user-profile-tabs .nav-link:hover {
  background-color: #e9ecef;
  color: #007bff;
  border-color: #dee2e6;
}

.user-profile-tabs .nav-link.active {
  color: #fff;
  background-color: #007bff;
  border-color: #dee2e6 #dee2e6 #fff;
}
