.badges-container {
    background-color: #fff;
    padding: 1em;
    border-radius: 6px;
    margin-bottom: 10px;
}
.section-container {
    height: 52em;
    overflow: auto;
}
.homepage-idea-container .card-col{
    margin-bottom: initial;
}
.announcements {
    background-color: #fff;
    padding: 0.5em 0.5em 0 0.5em;
    border-radius: 6px;
}
.announcements .carousel-item {
    min-height: 8em;
}
.announcements .carousel-item li.menu-item:not(:last-child) { 
    margin-top: 3px;
    margin-bottom: 3px;  
 }
 .announcements .carousel-item .action-button {
    border: 1px solid blue;
    height: fit-content;
    min-width: initial;
    padding: initial;
 }
.announcements .carousel-indicators {
    margin-bottom: 0;
}
.recent-kudos {
    background-color: #fff3cd;
}
.recent-questions {
    background-color: #f0f8ff;
}
.recent-questions-container {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    cursor: pointer;
}
.recent-questions-div {
    display: inline-block;
    border-radius: 6px;
    width: 4px;
    height: 3em;
    flex-shrink: 0;
}
.recent-questions-content{
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.completed-ideas-label {
    color: #78829d;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .5rem;
    line-height: .75rem;
}
.completed-idea-title {
    margin-bottom: 0!important;
}
.recent-questions-title {
    color: #252F4A;
    font-weight: 700;
    font-size: 1.075rem;
    text-transform: capitalize;
    word-break: break-all;
}
.recent-questions-desc {
    color: #78829D;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.85em;
    display: flex;
    justify-content: flex-end;
}
.kholab-home-card > .card-col > .idea-card, .kholab-home-card > .card-col > .idea_card:hover, .border-dashboard:hover {
    transform: none;
}
.dashboard-justify-center {
    justify-content: center;
}
.dashboard-justify-end {
    justify-content: flex-end;
}
.recent-badge-container {
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .25);
    margin: 10px;
    padding: 8px;
    transition: all .4s ease;
    width: 93%!important;
}
.kholab-dashboard {
    padding-bottom: 0;
}
.kholab-dashboard-actions {
    display: flex;
    justify-content: center;
    border-top: 1px solid lightgray;
    padding-top: 1em;
}
.kholab-dashboard-actions > button {
    border: none !important;
    border-bottom: 1px dashed #1b84ff !important;
    border-radius: 0;
}
.kholab-dashboard-heading {
    color: #071437;
    display: flex;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.8125rem;
}
.kholab-m-1 {
    margin: 1em;
}
.border-dashboard {
    border: 1px solid #f1f1f4;
}
.chapter-summary {
    padding: 1em;
    color: #252f4a;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
}
.summary-tile-div {
    display: flex;
    justify-content: space-around;
    margin-top: 0.75em;
}
.summary-tile-container {
    background-color: #fff;
    border: 1px solid #dbdfe9;
    width: 22%;
    cursor: pointer;
}
.summary-tile {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: center;
    padding: 1.5rem 3rem;
    background-color: #fff;
    min-height: 5em;
    border-radius: 5px;
}

.dashboard_badge_user {
    display: flex;
    justify-content: flex-end;
    text-transform: capitalize;
    font-size: 1rem;
    line-height: 1.25rem;
}
.badge_timestamp {
    color: #78829d;
    font-size: .75rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.summary-tile-count {
    color: #071437;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.summary-tile-link {
    border-bottom: 1px dashed #1b84ff;
}
.summary-tile-desc {
    display: flex; 
    color: #1b84ff;
    align-items: center;
}
.badge_container_desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.badge_container_desc_role_type {
    font-size: 0.5rem!important;
    padding: 0.25rem!important;
    width: fit-content;
}
.gold_badge_color {
    background-color: #FFD700;
}
.silver_badge_color {
    background-color: #C0C0C0;
}
.bronze_badge_color {
    background-color: #CD7F32;
}
.gold_badge_container {
    background: #f9d5174d;
}
.silver_badge_container {
    background: #cdcdcd4d;
}
.bronze_badge_container {
    background-color: #fb902638;
}
.dashboard-section-type-1 {
    margin: 0.5em 1em 0;
}
.dashboard-section-type-2 {
    margin: 0.5em 1em 0;
}
.dashboard-metrics-icon {
    height: 0.7em
}
.status-category-icon {
    display: flex;
    gap: 0.25em;
    width: fit-content;
}
.status-category-info-icon {
    color: #1B84FF;
}
.status-category-imp-icon {
    color: #7239EA;
}
.status-category-critical-icon {
    color: #17C653;
}
.dashboard-flex {
    display: flex !important;
}
.dashboard-flex-col {
    flex-direction: column;
}
.dashboard-modal-image {
    object-fit: contain;
    width: 100%;
}
.dashboard_gap_0_5 {
    gap: 0.5em;
}
.dashboard_font_weight_600 {
    font-weight: 600;
}
.dashboard-transform-capitalize {
    text-transform: capitalize;
}
.dashboard-badge-content {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 1em;
    min-height: 10em;
}
.sections-card {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #e9ecef;
}
.dashboard-kudos-content {
    font-size: 1em;
    line-height: 1.5em;
    gap: 0.5rem;
    margin-top: 1em;
    min-height: 10em;
}
.dashboard-chapter-head-heading {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: #071437;
    margin-bottom: 10px;
}
.external-link-button {
    text-transform: none;
    border: 1px solid #1b84ff;
    color: #1b84ff!important;
    padding: 0 5px;
    border-radius: 5px;
    text-decoration: none!important;
    cursor: pointer;
}
.kudos-author:not(:last-of-type)::after {
    content: ",";
}
