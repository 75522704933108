.badge-name-tag{
  text-align: start;
  margin-bottom: 2px;
  font-size: 14px;
}
.badge-role-type-tag{
  font-size: 10px !important;
}
.top-toolbar button {
  margin: 5px;
  border: 1px solid rgb(94, 170, 241);
  background-color: #6388c9;
  color: white;
  border-radius: 3px;
}
.top-toolbar {
  background-color: #fff;
}
.tabs-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.user-profile-heading {
  color: #071437;
  text-align: start;
  margin-top: 15px;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.8125rem;
}
