.tbody {
  border-top: none !important;
}

.large-modal {
  min-width: 1100px;
  min-height: 800px;
}

.table-bg {
  background-color: #cbe4de;
  font-size: 0.8em;
  overflow-wrap: anywhere;
}

.cell-border {
  border: 1px solid black;
}
