.stackedBarChart {
    display: flex; 
    justify-content: center;
    height: 350px;
    width : 800px;
}
.stackedBarChart .recharts-surface {
    height: 95%!important;
}
.stackedBarChart .recharts-wrapper {
    margin: 0 auto;
}
.stackedBarChart .recharts-legend-wrapper {
    max-height: 100px!important;
    overflow-y: scroll;
}
.stackedBarChart .recharts-tooltip-wrapper {
    max-height: 100%;
    background-color: #fff;
    overflow-y: scroll;
    z-index: 99999;
    min-height: 100px;
}
.recharts-legend-item-text {
    color: #252F4A!important;
}
