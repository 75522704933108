.left-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.leftbar {
  padding: 10px;
  margin: 8px;
}

.leftbar-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.leftbar-options {
  display: flex;
  flex-direction: column;
}

.leftbar-option {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.534);
}

a:hover {
  color: #000;
}

.leftbar-option > p {
  color: rgba(0, 0, 0, 0.534);
  font-size: 14px;
}

.link {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}

.link-tag-leftbar {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  font-weight: 700;
}

.link-tag:hover {
  border-right: 5px solid rgb(227 39 38);
}

.link-tag > .MuiSvgIcon-root {
  font-size: 18px;
  margin-right: 5px;
  color: rgb(29 59 109);
}

.tags {
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.534);
  margin: 0px 20px;
}

.tags > p {
  margin: 5px 0;
  cursor: pointer;
}

.tags > p:hover {
  color: #000;
}
.vote-links {
  list-style-type: none;
}
.link-ques {
  font-size: 13px;
  line-height: 21px;
  padding: 4px;
}
.PopularTags {
  padding: 10px;
  background: aliceblue;
  margin: 8px;
  /* margin-top:0 */
}
.multiplier {
  color: #848d95;
}

.count {
  color: #6a737c;
  font-size: 11px;
}
.popular {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}
.tag {
  display: inline-block;
  cursor: pointer;
  background-color: #e1ecf4;
  color: #39739d;
  font-size: 12px;
  padding: 5px 6px;
  border: 1px solid transparent;
  margin: 2px 5px 2px 0;
  line-height: 1;
  white-space: nowrap;
  border-radius: 3px;

  &:active {
    color: #0095ff;
  }

  &:hover {
    color: #2c5777;
    background-color: #d1e5f1;
    border-color: transparent;
  }
}
.heading-tags {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  font-weight: 700;
}
.post-title {
  color: #40a2e3;
  font-weight: 450;
}

.post-title:hover {
  color: #3468c0;
}
