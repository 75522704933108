@import './fnf_variables.scss';

@font-face {
  font-family: 'Neufreit';
  src: url('../../../media/FnF_media/fonts/Neufreit.otf');
}

@font-face {
  font-family: 'SourceSansProRegular';
  src: url('../../../media/FnF_media/fonts/SourceSansPro/SourceSansPro-Regular.ttf');
}

.fnf {
  * {
    margin: 0;
    padding: 0;
    font-family: 'SourceSansProRegular', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
  }

  background-color: $BodyBackgroundColor;

  button:hover {
    cursor: pointer;
  }

  .pagetooltip {
    color: white;
    margin: 0.5rem;
    font-size: 1.2rem;
  }

  .tickicon {
    color: $DotheRightThing !important;
    font-size: 1rem !important;
    margin: 0;
  }

  .crossicon {
    color: $KHRed !important;
    font-size: 1rem !important;
    margin: 0;
  }
  .withicon {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;

    span {
      flex: 1;
    }
  }

  .ghosticon {
    opacity: 0;
    margin: 0;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    justify-content: flex-end;

    .sidebarcontent {
      position: fixed;
      top: 0;
      right: 0vw;
      bottom: 0;
      width: 40vw;
      background-color: white;
      transition: right 1s ease-in;
      padding: 16px;

      .closeicon {
        color: black;
        font-size: 1.4rem;
        padding: 8px;
        border-radius: 5px;
        margin-right: 0.5rem;

        &:hover {
          background-color: #f0f0f0;
          cursor: pointer;
        }
      }

      .titlerow {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          margin: 0;
          font-weight: bold;
          font-size: 1.4rem;
        }

        > div {
          display: flex;
          align-items: center;
        }
      }
      h4 {
        font-size: 1.2rem;
      }
      .actionbtns {
        button {
          font-weight: bold;
          font-size: 0.9rem;
        }
        .dstrigger {
          color: $KHBlue;
          margin-left: 8px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .bar1data {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;

    .eachdata {
      border: 2px solid $QuadraticColor;
      padding: 8px 0;
      width: 13%;
      text-align: center;

      h4 {
        margin: 0;
        font-weight: bold;
        font-size: 1.2rem;
      }
      label {
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }
  }

  .MuiPaper-root {
    background: none;
    margin-top: 0.5rem;

    .danger {
      background-color: $KHRed;
    }
    .safe {
      background-color: $DotheRightThing;
    }

    thead tr th {
      background-color: $MiamiBlue;
      color: $TableTextColor;
      font-size: 0.9rem;
      padding: 10px;
      text-align: center;
      border-right: 1px solid white;
      line-height: 16px;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      font-weight: bold;
    }
    thead tr th:hover {
      cursor: pointer;
    }

    .snowflake_data {
      background-color: $MiamiBlue;
    }
    .sap_data {
      background-color: $DemandDiversity;
    }
    .levante_greenfield_data {
      background-color: $ConsumerObsessed;
    }
    .fourkites_data {
      background-color: $DotheRightThing;
    }
    .tableau_data {
      background-color: $KHBlue;
    }

    tbody tr td {
      font-size: 0.8rem;
      text-align: center;
      padding: 8px;
      border-bottom: 1px solid $MiamiBlue;
      border-right: 1px solid $MiamiBlue;
      white-space: nowrap;

      span,
      button {
        border: none;
        color: $MiamiBlue;
      }
    }
  }

  .colorcodes {
    display: flex;
    > div {
      margin-right: 16px;
      display: flex;
      align-items: center;

      label {
        font-size: 1.1rem;
      }
    }
    .colorbox {
      width: 16px;
      height: 16px;
      margin-left: 16px;
      margin-right: 8px;
    }
    .snowflakebox {
      background-color: $MiamiBlue;
    }
    .sapbox {
      background-color: $DemandDiversity;
    }
    .levante_greenfieldbox {
      background-color: $ConsumerObsessed;
    }
    .fourkitesbox {
      background-color: $DotheRightThing;
    }
    .tableaubox {
      background-color: $KHBlue;
    }
  }

  .actionitems {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;

    .searchbar {
      display: flex;
      align-items: center;
      border: 2px solid $QuadraticColor;
      width: fit-content;
      padding: 0;
      background-color: white;
      height: 30px;
      overflow: hidden;
      input {
        border: none;
        padding: 5px;
        height: 30px;
        font-size: 0.9rem;
        &:focus {
          outline: none;
        }
      }
      .searchbtnwrapper {
        width: 30px;
        height: 30px;
        padding: 0;
        flex: 1;
        background-color: $QuadraticColor;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        background-color: $QuadraticColor;
      }
    }
    button {
      padding: 8px;
      background-color: $SecondaryColor;
      color: $SecondaryTextColor;
      border: none;
      min-width: 120px;
      font-weight: 600;
    }
  }

  .eachcomment {
    background-color: $QuadraticColor;
    padding: 8px;
    border-radius: 8px;
    margin-top: 16px;

    .reditems {
      color: red;
    }
    .greenitems {
      color: green;
    }
  }

  .appendDollar::before {
    content: '$ ';
  }

  #nodatawarn {
    text-align: center;
    padding: 20px;
  }

  .loaderbox {
    width: auto;
  }

  .itemscontainer {
    height: 82vh;
    overflow: scroll;

    .loaderbox {
      width: 100%;
    }
    h4 {
      font-weight: bold;
      text-align: left;
      font-size: 1rem;
    }
    p {
      margin: 0;
    }
  }

  .itemscontainer {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .itemscontainer::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .orderdetails {
    padding: 1rem;

    .orderdetailsheading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > button > svg {
        color: $KHBlue;
      }
    }

    > h4 {
      color: $MiamiBlue;
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 1.2rem;
    }

    .detailsinfo {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;

      .content {
        margin-top: 0.5rem;
        width: 46%;

        > div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.3rem;

          > span {
            font-size: 1rem;
            text-align: right;
          }

          .title {
            margin: 0;
            margin-right: 2rem;
            font-weight: bold;
            text-align: left;
          }
        }
      }

      .onlyleft {
        width: 100%;

        > div {
          span {
            width: 30%;
            text-align: center;
            margin-right: 2px;
          }
          .heading {
            font-weight: bold;
            background-color: $QuadraticColor;
            padding: 0.3rem 0.5rem;
            font-size: 0.9rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .title {
            text-align: left;
          }
          .remark {
            flex: 1;
            color: $KHRed;
          }
        }
      }
    }
  }
}

.noaccess {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 85vh;

  h4 {
    font-weight: 500;
    text-align: center;
    padding: 10px;
    line-height: 50px;
  }
  span {
    color: $KHBlue;
    font-weight: bold;
  }
}
