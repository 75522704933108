.stack-index {
  display: flex;
  background-color: white;
  min-height: 85vh;
}

.stack-index-content {
  display: flex;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
}
.pagination {
  display: flex;
  letter-spacing: 1px;
  font-family: monospace;
}
.page-item {
  margin: 5px;
}
ul.pagination li.active {
  background-color: #1d3b6d;
}
