@import '../fnf_variables.scss';

.emailpopup {
  background-color: $BodyBackgroundColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  border-radius: 5px;

  > div {
    width: 100%;
    margin-top: 8px;
    input,
    textarea {
      width: 100%;
    }
    textarea {
      height: 200px;
    }
  }
  button {
    background-color: $KHBlue;
    border: none;
    color: white;
    padding: 5px;
    margin-top: 16px;
  }
}
