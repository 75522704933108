$BodyBackgroundColor: rgba(245, 245, 245, 1);

$PrimaryColor: rgba(0, 0, 0, 1);
$PrimaryTextColor: rgba(255, 255, 255, 1);

$SecondaryColor: rgba(15, 60, 71, 1);
$SecondaryTextColor: rgba(255, 255, 255, 1);

$TertiaryColor: rgba(194, 204, 207, 1);
$TertiaryTextColor: rgba(255, 255, 255, 1);

$QuadraticColor: rgba(217, 217, 217, 1);
$QuadraticTextColor: rgba(0, 0, 0, 1);

$TableColor: rgba(33, 136, 159, 1);
$TableTextColor: rgba(255, 255, 255, 1);

$KHBlue: rgb(18, 42, 78);
$KHRed: rgb(224, 39, 38);
$MiamiBlue: rgb(9, 175, 220);
$ConsumerObsessed: rgb(144, 0, 103);
$DareToDoBetter: rgb(15, 104, 173);
$DemandDiversity: rgb(254, 190, 65);
$Ownit: rgb(178, 30, 38);
$DotheRightThing: rgb(0, 130, 61);
$ChampionGreatPeople: rgb(0, 143, 148);
