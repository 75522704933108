.glossary-app {
  padding: 15px;
}

.glossary-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.glossary-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e6e6e6;
  transition: transform 0.3s ease;
  transition: background-color 0.3s ease;
  height: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  position: relative;
}

.glossary-card-khc-logo {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 15%;
  height: auto;
}

.glossary-card:hover {
  transform: translateY(-5px);
  background-color: #f0f0f0;
}

.glossary-abc-list-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.glossary-abc-list-group .btn {
  flex-shrink: 1;
}

.glossary-abc-list-group .list-group-item.disabled {
  background-color: rgb(238, 238, 238);
}

.glossary-abc-list-group .list-group-item.selected {
  background-color: green;
  border-color: green;
}

.glossary-abc-list-group .glossary-abc-button:hover {
  transform: scale(1.05);
  transition: background-color 0.3s, color 0.3s;
  background-color: #f0f0f0;
  color: black;
}

.glossary-abc-list-group .glossary-abc-button.selected {
  color: white;
}

.glossary-abc-list-group .glossary-abc-button.selected:hover {
  background-color: green;
}

.glossary-abc-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  border: 0;
}

.glossary-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.glossary-sort-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 2px;
}


.glossary-sort-buttons .btn {
  border-color: green;
  background-color: green;
}

.glossary-search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
}

.glossary-search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.glossary-header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 2px;
}

.glossary-search-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0;
  background-color: green;
  color: white;
  cursor: pointer;
  transition: 0.3s opacity;
}

.glossary-search-button:hover {
  opacity: 1;
}

.glossary-search-clear-button {
  display: inline-block;
  border: none;
  background: transparent;
  padding: 10;
}

.btn-primary.glossary-btn-primary {
  background-color: #1d3c6d;
  border-color: #1d3c6d;
}

.btn-primary.glossary-btn-primary:hover {
  background-color: #1d3c6d;
}

.btn-primary.glossary-btn:hover {
  transform: scale(1.05);
  background-color: #f0f0f0;
}

.glossary-button-approval-container {
  position: relative;
  display: inline-block;
}

.glossary-bubble-approval {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: #fff;
  border-radius: 100%;
  padding: 4px 8px;
  font-size: 12px;
}

.glossary-pagination-container {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  align-items: center;
  margin-top: 20px;
}

.glossary-pagination-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #1d3c6d;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.glossary-pagination-button:hover {
  background-color: #1d3c6d;
}

.glossary-pagination-text {
  margin: 0;
  margin-left: auto;
}

.glossary-modal-body {
  max-height: 90vh;
  overflow-y: scroll;
}
