.bdm-chart-container {
  width: 100%;
  padding: 8px;
  height: auto;
}

.bdm-controls-stack {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
}


.bdm-form-control {
  flex: 0 0 calc(50% - 8px);
  min-width: 200px;
}


.bdm-form-control-all {
  flex: 0 0 calc(50% - 8px);
}

.bdm-form-control-full {
  flex: 0 0 calc(50% - 8px);
}

.bdm-chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 100%;
}


.bdm-MuiChip-root {
  margin: 2px;
}

@media (max-width: 600px) {
  .bdm-controls-stack {
    flex-direction: column;
    gap: 16px;
  }

  .bdm-form-control,
  .bdm-form-control-all,
  .bdm-form-control-full {
    flex: 1 1 100%;
    width: 100%;
  }
}
