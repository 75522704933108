.nps-form-main {
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  max-width: 80%;
  background-color: #fdfdfd;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
}
.nps-form-main > div {
  display: flex;
  justify-content: center;
}
.nps-form-main > .quill {
  display: block;
}
.input-group-nps {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  width: 70%;
}
.input-group-nps > label {
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.3rem;
}
.input-group-nps > div > label {
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.3rem;
}
.input-group-nps > div {
  display: flex;
  justify-content: center;
}
.input-nps {
  width: 100%;
  display: flex;
  justify-content: center;
}
.input-nps > input {
  padding: 0.3rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
}
.input-nps > input:focus {
  outline: none;
}
.input-nps > div {
  padding: 0.5rem 1rem;
  background: #e34728;
  margin-left: 0.5rem;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.add-btn-nps {
  padding: 0.5rem 1rem;
  background: #0f3460;
  border: none;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  width: 60%;
  align-self: center;
  margin-top: 17px;
}
.add-btn-nps-1 {
  padding: 0.5rem 1rem;
  background: #0f3460;
  border: none;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  align-self: center;
  margin-top: 17px;
}
.center-nps {
  display: flex;
  justify-content: center;
}
.submit-btn-nps {
  width: 75%;
  padding: 0.5rem 1rem;
  border: 1px solid #e34728;
  background: #fff;
  margin: 1.2rem auto;
  color: #e34728;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
}
.submit-btn-nps-edit {
  width: 50%;
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid #e34728;
  background: #fff;
  margin: 1.2rem auto;
  color: #e34728;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
}
.dialog-box-nps {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  grid-gap: 1rem;
}
.dialog-box-nps > input,
.dialog-box-nps > select {
  padding: 0.5rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.dialog-box-nps > input:focus,
.dialog-box-nps > select:focus {
  outline: none;
}
.hide {
  display: none;
}
.nps {
  background-color: #fff;
  min-height: 100vh;
}
.radio-btn {
  margin: 10px;
}
/* === HEADING STYLE #1 === */
.one h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: '';
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: '';
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}
.radio-input-nps {
  background: none !important;
}
.radio-label-nps {
  color: #080808;
  margin-left: 10px;
}
.date-form {
  margin: 8px;
  padding: 10px;
}
.date-label {
  margin: 5px;
}
.date-field {
  margin: 5px;
}
.check-field {
  margin-left: 25px;
}
.home-nps {
  text-align: center;
  min-height: 67vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* CSS */
.button-2 {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 5px;
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.add-btn-plus {
  align-content: center;
  padding: 7px;
  margin: 22px;
  font-size: 22px;
  background: #a3a3a3;
}

.options {
  padding: 3px;
  border: 1px solid #ddd;
}
.view-form {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 15px;
  left: 0;
  justify-content: center;
  background-color: #e2f2ff;
  border: 1px solid #c6e5ff;
  align-items: center;
}
.view-form > .input-group > .input {
  justify-content: center;
}
.view-form > .input-group > .input > input {
  width: 70% !important;
}

.view-form > .input-group > label {
  justify-content: center;
  display: flex;
  font-size: 17px;
}
.main-view-form {
  margin-bottom: 20px;
}
textarea {
  border: 1px solid #ddd;
}
.center-view-form-btn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.react-quill-form {
  width: 100%;
}
.react-quill-form > .ql-container > .ql-editor {
  height: 47px;
  margin: 10px;
}
.react-quill-form > .ql-container.ql-snow {
  border-radius: 7px;
}
.tags > div {
  background: '#fff';
  width: 100% !important;
}
.rti--container.go2761622576 {
  width: 100%;
}
.options {
  margin: 5px;
}
.option-btn {
  background: revert-layer;
  border: 1px solid #b3b3b3;
  margin: 5px;
}
.basic-multi-select {
  width: 100%;
}
.titleInput {
  border: none;
  width: 60%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  align-self: center;
  margin: 13px;
  line-height: 32px;
  font-size: 19px;
}
.titleInput:focus {
  outline: none;
  border-bottom: 1px solid #9d9eff;
}
.options:focus {
  outline: none;
  border: 1px solid #9d9eff;
}
.form-fields:focus {
  border: 1px solid #9d9eff;
}

.surveyGroupNameInput {
  width: 100% !important;
}
.all-form-card:hover {
  transform: scale(1.06);
  transition: 0.3s ease-in;
}

.main-section-nps {
  background: #fff;
  min-height: 100vh;
}
.sub-head-nps {
  display: flex;
  justify-content: center;
  font-size: 19px;
}
.head-main-nps {
  display: flex;
  justify-content: center;
}
.nav-menu-nps {
  display: flex;
  padding-top: 8px;
  background: #fff;
}
.textarea-cancel {
  padding: 0.5rem 1rem;
  background: #e34728;
  height: fit-content;
  margin-left: 0.5rem;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.descInput{
  width: 60%;
  margin: 5px;

}
.descInput:focus {
  outline: none;
  border: 1px solid #9d9eff;
}
.input-nps-rating > .rating > div{
  padding: 0.5rem 1rem;
  background: #e34728;
  margin-left: 0.5rem;
  color: #fff;
  font-weight: 700;
  display: flex;
  cursor: pointer;
}
.MuiRating-root {
  font-size: 2.5rem !important;
}
.MuiRating-root > span {
  margin: 5px !important;
}
.MuiRating-icon > svg {
  font-size: 2.5rem !important;
}
.resVal{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%; 
}
.addUser{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.email-nps-modal > div {
  width: 61% !important;
}
.email-nps-modal > div > div{
  min-height: 65vh;
  max-height: 95vh;
}
.email-nps-modal > div > div > .modal-body{
  min-height: 60vh !important;
  max-height: 100vh !important;
}

input[type=radio] {
  border: 1px solid #fff;
  padding: 0.5em;
}

input[type=radio]:checked {
  background: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==) no-repeat center center;
  background-size: 9px 9px;
}

input[type=radio]:focus {
  outline-color: transparent;
}

.input-group>.ql-tooltip .ql-editing{
  left: 0px !important;
}

.email_card:hover {
    transform: translateY(-5px);
    background-color: #f0f0f0;
  }

.titleEmail{
  width: 40%;
}

/* nps btn css */

.nps-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  font-size: 22px;
  color: #295cad;
  font-family: 'Open Sans';
}

.row-nps {
  border-radius: 5px;
  border: 1px solid #295cad;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn-input {
  width: 5rem;
  height: 45px;
  border: 1px solid #295cad;
  background-color: transparent;
  font-size: 1.375rem;
  color: #295cad;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0px;
  margin: 0px;
}

.btn-input:hover {
  background-color: #295cad;
  color: #fff;
}

.btn-input.active {
  background-color: #295cad;
  color: #fff;
}

.btn-0 {
  border-radius: 5px 0px 0px 5px;
}

.btn-10 {
  border-radius: 0px 5px 5px 0px;
}
.text-edit-me {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 11px;
}

.not-at-all, .not-at-all1 {
  line-height: 15px;
}

.not-at-all {
  width: 97px;
  position: relative;
  line-height: 15px;
  display: inline-block;
  height: 15px;
  flex-shrink: 0;
}

.not-at-all1 {
  position: relative;
  line-height: 15px;
}

.text-edit-me {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 11px;
}

.menu-nps-divider {
  border-left: 1px solid #cdcdcd;
  margin-left: 30px;
  margin-right: 20px;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.ql-size-small {
  font-size: 12px;
}
.ql-size-normal {
  font-size: 16px;
}
.ql-size-large {
  font-size: 20px;
}
.ql-size-huge {
  font-size: 24px;
}
.nps-edit-survey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
